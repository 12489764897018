import styled from 'styled-components'

export const DropDownContainerBox = styled.div`
/* right: 30px;
position: absolute;
margin-top: 30px; */
`;

export const DropDownNavBtn = styled.div`
width: 25px;
height: 25px;
display: flex;
align-items: center;
position: relative;
background: transparent;
display: flex;
cursor: pointer;

 svg{

     position: relative;
     visibility: visible;
 }
    span{
        color: #000;
    white-space: nowrap;
    height: 100%;
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .02rem;
    background: var(--white);
    margin-left: 2px;
    border-radius: 2px;
    font-weight: 400; 
    }


`

export const DropdownList = styled.ul`
margin: 0;
margin-top: ${props => props.marginTop ? props.marginTop : ''};
padding: ${({ padding = 0 }) => padding};
background: ${props => props.listBackground ? props.listBackground : 'var(--dropdownBackground)'};
box-shadow: ${({ boxShadow }) => boxShadow || 'var(--dropdownBoxShadow)'};
border: ${({ border }) => border || 'var(--dropdownBorder)'};
position: absolute;
border: ${props => props.border ? props.border : ''};
width: 100%;
z-index: 2;
height: ${({ height = 'min-content' }) => height};
max-height: ${({ maxHeight }) => maxHeight};
overflow: ${({ maxHeight }) => maxHeight ? 'hidden auto' : ''};
display: flex;
flex-direction: column;
justify-content: ${({ justifyContent }) => justifyContent};
input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
}

`;

export const DropdownListContainer = styled.div`
    
`;

export const DropdownContainer = styled.div`
    height: 100%;
    width: ${props => props.width ? props.width : '100%'};
    position: relative;
    box-shadow: ${({ boxShadow }) => boxShadow};
    background: ${props => props.background ? props.background : 'var(--grey)'};
    text-transform: ${({ textTransform }) => textTransform || 'var(--dropdownTextTransform)'};
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    border: ${props => props.border ? props.border : ''};
font-size: 10px;
font-weight: 500;
letter-spacing: ${({ letterSpacing }) => letterSpacing || 'var(--dropdownLetterSpacing)'};
position: relative;
    
`;

export const ListItem = styled.li`
    height: ${props => props.height ? props.height : '40px'};
    list-style: none;
    display: flex;
    position: relative;
    letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : ''};
    font-size: ${props => props.fontSize ? props.fontSize : ''};
    font-weight: ${props => props.fontWeight ? props.fontWeight : ''};
    align-items: center;
    flex-shrink: ${({ flexShrink }) => flexShrink};
    margin: 0;
    padding: ${props => props.itemPadding ? props.itemPadding : '0 20px'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-end'};
    user-select: none;
    cursor: ${({ hoverOff }) => hoverOff ? 'default' : 'pointer'};
    background: ${props => props.isSelected ? 'var(--dropdownSelectedItemBackground)' : 'transparent'};
    color: ${props => props.isSelected ? props.colorOnSelect ? props.colorOnSelect : 'rgb(255,255,255)' : props.color ? props.color : 'var(--dropdownColor)'};
    :hover{
        background: ${({ hoverOff }) => hoverOff || 'var(--dropdownHoverBackground)'};
    }


`;

export const BackgroundWrapper = styled.div`
position: fixed;
top: 0;
right: 0;
width: 100%;
height: 100%;
background: var(--purpleGrey);
overflow: hidden;
z-index: 1;
`;

export const RemoveButton = styled.div`
    cursor: pointer;
    margin-left: 5px;
    /* position: absolute;
    right: ${props => props.isDash ? '20px' : ''}; */

`;

export const LabelStyled = styled.label`
    position: relative;
    font-size:  1em;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;

export const DropdownHeader = styled.div`
    height: ${({ headerHeight }) => headerHeight};
    width: ${({ headerWidth }) => headerWidth};
    box-shadow: ${({ boxShadow }) => boxShadow};
    font-weight: ${({ fontWeight }) => fontWeight};
    display: flex;
    align-items: center;
    margin-left: 20px;
    user-select: none;
    img{
        width: 12px;
        position: absolute;
        right: 20px;
    }
    cursor: pointer;
`;

export const DropdownArrow = styled.img`
    transform: ${props => props.shouldRotate ? 'rotate(180deg)' : '0'};
`;