import styled from 'styled-components'

export const List = styled.ul`
display: grid;
grid-template-columns: ${({ col }) => col};
padding: 0;
max-width: ${({ maxWidth = '90%' }) => maxWidth};
flex-wrap: ${({ wrap }) => wrap};
justify-content: ${({ justifyContent }) => justifyContent};
gap: ${({ gap }) => (gap)};

li:not(:last-child):not(:first-child){
    /* margin: 0 10px; */
}
@media screen and (max-width:768px) {
    flex-direction: column;
    max-width: 100%;
    position: relative;
    flex-wrap: ${({ wrap }) => wrap};
    grid-template-columns: ${({ mobileCol }) => mobileCol};
}
`;

export const Text = styled.p`
color: ${({ color }) => color};

`;

export const ListItem = styled.li`
    /* height: 40px; */
    list-style: none;
    text-align: center;
    flex-grow: ${({ flexGrow = 1 }) => (flexGrow)};
    flex-shrink: 1;
    flex-basis: ${({ flexBasis }) => (flexBasis)};
    max-width: ${({ maxWidth }) => maxWidth};
    p{
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        color: ${({ listParagraphColor = 'var(--secondary)' }) => listParagraphColor};
    }
    h3{
        margin: 0;
    }
    >div{
        gap: ${({ gap }) => (gap)};
    }
    
`;

export const Icon = styled.img`
position: relative;
height: 100%;
width: 100%;
max-height: 80px;
/* max-width: 120px; */
object-fit: contain;
`;

export const ComponentWrapper = styled.div`
position: relative;
height: 100%;
width: 100%;
/* max-width: 120px; */
max-height: 80px;
object-fit: contain;
display: flex;
justify-content: center;

`;

export const H2 = styled.h1`
color: ${({ color }) => color};
transition: color 0.5s ease-in-out;
`