import React, { useState, useEffect, useRef } from 'react'

import Dropdown from '../Dropdown'
import Flexbox from '../Flexbox'

import { InnerContainer, TagItem, TagsInputContainer, Input } from './SearchTermHints'

const SearchTermHints = ({
    height,
    minHeight,
    marginTop,
    dropdownRef,
    trigger, // input field is default
    suggestions = [],
    setSuggestions,
    getAndFormatSearchResults,
    dropdownSelection,
    setDropdownSelection,
    shouldExecuteSearch,
    shouldResetSearchSuggestions,
    listContainerRef,
    currSelection,
    resetSearchResults,
    delay = 500

}) => {

    const initial = useRef(true)
    // const listContainerRef = useRef(null)
    const [showDropdown, setShowDropdown] = useState(false)


    const searchFunction = async (canceled) => {
        const res = await getAndFormatSearchResults()
        if (!canceled) {
            if (res && res.length > 0) {
                setSuggestions(res)
                setDropdownSelection(null)
                setShowDropdown(true)
            } else {
                setSuggestions([])
                setDropdownSelection(null)
                setShowDropdown(false)
            }
        }

    }

    const debounce = (callback, wait) => {
        console.log("debouncing")
        let timeout;
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => { callback.apply(this, args) }, wait)
        }

    }

    useEffect(() => {
        if (initial.current) {
            // note we can mutate this directly instead of using a "setStateXyz"
            // function because it's a useRef
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        const throttle = (func, delay) => {
            console.log("func", func, delay)
            let inProgress = false;
            return (...args) => {
                if (inProgress) {
                    return
                }
                inProgress = true
                setTimeout(() => {
                    func(...args)
                    inProgress = false;
                }, delay)
            }
        }
        if (shouldExecuteSearch()) {
            let canceled = false
            const timer = setTimeout(async () => {
                await searchFunction(canceled)
            }, delay)
            return () => {
                canceled = true
                clearTimeout(timer)
            }

            // throttle(searchFunction, delay)

        } else if (shouldResetSearchSuggestions()) {
            if (typeof (resetSearchResults) === 'function')
                resetSearchResults()
        }

    }, [trigger])

    return (
        <>
            {suggestions.length > 0 &&
                <Flexbox position='absolute' width="100%" height={height} minHeight={minHeight} >
                    <Dropdown
                        dropdownRef={dropdownRef}
                        listContainerRef={listContainerRef}
                        label='Tempo'
                        headerHeight="49px"
                        itemHeight="49px"
                        maxHeight={4 * 49 + 'px'}
                        dropdownListMarginTop={marginTop}
                        showHeader={false}
                        name={'Suggested Tags'}
                        marginBottom={'1rem'}
                        options={suggestions}
                        menuState={showDropdown}
                        handleStateChange={() => setShowDropdown(!showDropdown)}
                        currSelection={currSelection ? currSelection : null}
                        valueX={[]}
                        flexShrink={0}
                        id={'suggested-tags'}
                        setValidContinue={() => { }}
                        justifyContent="start"
                        textAlign="left"
                        // letterSpacing="0.1rem"
                        // fontSize="var(--fontSmall)"
                        // fontWeight="700"
                        // boxShadow='0 0 2px #000'
                        // background="#000"
                        width="100%"
                    ></Dropdown>
                </Flexbox>
            }
        </>
    )
}

export default SearchTermHints