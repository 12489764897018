import React, { useState, useEffect, useRef } from 'react'

import TextArea from '../TextArea'

import { List, ListItem, Check } from './RadioButtons.styles'

import Flexbox from '../Flexbox'

const RadioButtons = ({ options, onClick, selected, listWidth, textAreaOnChange, maxWidth }) => {

    return (
        <List listWidth={listWidth} maxWidth={maxWidth}>
            {
                Object.keys(options).map((option, index) => {
                    const _option = options[`${option}`]
                    return (
                        <Flexbox column key={(_option['id'] || index) + 'container'} alignItems="start" width="auto" gap="5px">
                            <ListItem id={_option['id'] || index} key={_option['id'] || index} name={_option['id']} checked={selected === _option['id']} onClick={() => { onClick(_option['id']) }}>
                                <Check className="check" checked={selected === _option['id']} />
                                <label htmlFor={_option['id']}>{_option['label']}</label>

                            </ListItem>
                            {
                                (_option.textArea !== undefined && textAreaOnChange) &&

                                <TextArea onFocus={() => { onClick(_option['id']) }} margin={"0 0 0 30px"} onChange={textAreaOnChange} />

                            }
                        </Flexbox>
                    )
                })
            }
        </List >
    )
}

export default RadioButtons