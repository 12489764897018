import React from 'react'
import PageContentContainer from '../PageContentContainer'
import Flexbox from '../Flexbox'
import machineLearningMarketing4 from '../../images/machineLearningMarketing4.svg'
import quickAndEasy from '../../images/quickAndEasy.svg'
import chess from '../../images/summit.svg'
import silverPlatter from '../../images/silverPlatter.svg'
import sparkInterest from '../../images/sparkInterest.svg'
import { RLink } from '../PrivacyPolicy'
import { H2 } from '../Section/Section.styles'
const index = () => {
    const props = {
        flexDirection: 'column',
        lineHeight: "1.3em",
        flexBasis: "50%",
        alignItems: "start",
        flexGrow: '1',
    }
    const props2 = {
        flexWrapMobile: 'wrap'
    }
    return (
        <PageContentContainer
            column
            display="flex"
            // marginTop="0"
            position="relative"
            // background="var(--formBackground90)"
            alignText="start"
            // top="0"
            // color="#fff"
            width="100%"
            height="100%"
            // background="var(--formBackground)"
            justifyContent="start" alignItems="center" flexDirection="row" padding="30px 0 0">
            <Flexbox column padding="0 50px 50px" gap="5px">
                <h1 style={{ margin: '0', fontSize: '2.2rem', color: '#000' }}>About</h1>
                {/* <Flexbox> */}

                <h2 style={{ fontSize: '1.3rem', margin: '0', fontWeight: '300', textAlign: 'center', maxWidth: '700px', color: 'var(--grey1)' }}>
                    We're here to leverage our marketing and machine learning skills to generate real-time leads at a great price.
                </h2>
                {/* <img src={machineLearningMarketing} height="100%" style={{ maxHeight: '400px', width: 'auto' }} /> */}
                {/* <img src={machineLearningMarketing2} height="100%" style={{ maxHeight: '400px', width: 'auto' }} /> */}
                {/* <img src={machineLearningMarketing3} height="100%" style={{ maxHeight: '500px', width: 'auto', margin: '20px 0 0' }} /> */}
                <img src={machineLearningMarketing4} height="100%" style={{ maxHeight: '500px', width: 'auto', margin: '20px 0 0' }} />

            </Flexbox>
            <Flexbox background="#f2f2f2" padding="50px" justifyContent="space-between" gap="50px" {...props2}>
                <Flexbox column lineHeight="1.3em" {...props} color="#000">
                    <p> <h1 style={{ lineHeight: '1.2em', textTransform: 'uppercase' }}>Helping your firm reach the top</h1>
                    Clicks on search engine ads for terms such as “car accident lawyer” can cost $20 each, and the average website takes 40-50 clicks to generate one lead. That means generating one attorney lead can cost $800-$1,000.
                    </p>
                    <p>
                        Those may be average numbers, but by combining ultra-effective ads with high-conversion landing pages, we generate leads for much lower prices.
                    </p>
                    <p>
                    This allows us to make a profit ourselves, while charging you a per lead cost that is significantly lower than many lawyers achieve with in-house advertising campaigns.
                    </p>
                    <p>
                        We do the hard part for you by creating a custom ad campaign that gets results.  We take on the risk, paying for the clicks, while you only pay for real leads.
                    </p>
                </Flexbox>
                <img src={chess} height="100%" width="auto" style={{ maxHeight: '400px' }} />
                {/* <Flexbox flexBasis="50%">
                </Flexbox> */}
            </Flexbox>
            <Flexbox padding="50px 50px 50px 0" width="100%" justifyContent="space-between" {...props2} color="#000">
                <img src={silverPlatter} height="90%" width="auto" style={{ maxHeight: '500px' }} />
                <Flexbox column lineHeight="1.3em"  {...props} margin="0 0 0 50px" >

                    <p><h1 style={{ lineHeight: '1.2em', textTransform: 'uppercase' }}>Exclusive and High Quality</h1>All leads are exclusive.  That means when we generate a lead, unlike other companies, we guarantee it will be sold to you and only you.  We never sell leads to multiple attorneys.
                    </p>
                    <p>We serve up lawyer leads for most major categories, like auto accident, personal injury, mass tort and criminal defense.
                    </p>
                    <p>We use microtargeting to focus on clients who are statistically more likely to end up retaining your services.
                    </p>
                </Flexbox>
            </Flexbox>
            <Flexbox background="#f2f2f2" padding="50px" justifyContent="space-between" gap="50px" {...props2} color="#000"  flexWrapMobile="wrap-reverse">
                <Flexbox {...props}>
                    <h1 id="how-onboarding-works" style={{ lineHeight: '1.2em', textTransform: 'uppercase' }}>How does onboarding work?</h1>
                    <p>
                        Fill out our <RLink to="/get-started" target="_blank">“Get Started” form</RLink>, which only takes about 1 minute.  You’ll tell us which lead types you're interested in and any other information you'd like to provide.
                    </p>
                    <p>
                        We’ll confirm exact pricing for all lead types you’ve selected and send you a checkout link.
                    </p>
                    <h3>Payment Processing</h3>
                    <p>
                        Fund your account to begin receiving leads. You can pay with a credit or debit card, or bank transfer.  We use Stripe as our payment processor, so we never receive or store your credit card information.<p></p>

                        Once your order is fulfilled and the balance is depleted, simply add funds to continue receiving leads. No commitments.  No auto-billing.
                    </p>
                    <p>

                    </p>
                </Flexbox>
                <img src={quickAndEasy} height="100%" width="auto" style={{ maxHeight: '400px', maxWidth: '35%', minWidth: '200px', margin: '0 auto' }} />
            </Flexbox>
            <Flexbox color="#000" padding="50px" justifyContent="space-between" gap="50px" width="100%" {...props2} flexWrapMobile="wrap-reverse">
                <img src={sparkInterest} height="100%" width="auto" style={{ maxHeight: '400px', maxWidth: '35%', minWidth: '200px', margin: '0 auto' }} />
                <Flexbox column lineHeight="1.3em" flexBasis="50%" {...props}>
                    <h1 style={{ lineHeight: '1.2em', textTransform: 'uppercase' }}>How do your lead generation sites work?</h1>
                    <p style={{ maxWidth: '700px' }}>
                    When a potential client lands on one of our sites, we ask for detailed information about their case and personal situation. This allows us to connect them with one lawyer or law firm who matches their needs. We screen for a valid injury case, or ability to pay attorney fees, sending you only qualified leads.<p></p>Our lead gen sites create a good first impression, which we believe helps motivate clients to move forward.
                    </p>
                </Flexbox>
            </Flexbox>
        </PageContentContainer>
    )
}

export default index