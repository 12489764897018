import { createGlobalStyle } from 'styled-components';

const hexToRgbA = (hex, alpha = 1) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
}


export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --navBarHeight: 60px;
        --defaultContainerHeight: calc(100vh - var(--navBarHeight));
        --background: #fff;
        --backgroundActiveLi: rgba(255,255,255,0.25);
        --borderRightActiveLi: 2px solid rgb(255,255,255);
        --yellow: #F2BD00;
        --white: #fff;
        --primary: #fff;
        --secondary: #575555;
        --black: #000;
        --red: #9e0000;
        --green: #03cc00;
        --formBlue1: rgb(56, 106, 255);
        --formBlue2: rgb(27, 134, 246);
        --defaultBorder: 1px solid var(--primary);
        --borderMediumThick: 2px solid var(--primary);
        --defaultFont: 'Albert Sans', sans-serif;
        --formColor: #ffffff;
        --defaultPagePadding: 0 50px;
        --formBackground: linear-gradient(var(--formBlue1), var(--formBlue2));
        --formBackgroundAngled: linear-gradient(45deg,var(--formBlue1), var(--formBlue2));
        --formBackgroundAngled2: linear-gradient(45deg,var(--formBlue2), var(--formBlue1));
        --formBackgroundAngledTransparent: linear-gradient(45deg,rgba(56, 106, 255,.95), rgba(27, 134, 246,.95));
        --greyBackground: #262626;
        --greyBackground: #262626;
        --grey1: #575555;
        --darkGrey: #000000c3;
        --buttonFontSize: 13px;
        --dropdownLetterSpacing: '';
        --dropdownBoxShadow: none;
        --dropdownBackground: rgba(255,255,255,0.90);
        --dropdownBorder: var(--defaultBorder);
        --dropdownTextTransform: none;
        --dropdownHoverBackground: rgb(27, 134, 246, 0.5);
        --dropdownSelectedItemBackground: var(--formBlue2);
        --dropdownColor: var(--formBlue1);
        --inputBoxShadow: none;
        --inputBackground: rgba(255,255,255,0.25);
        --inputPlaceHolderColor: #d6eaff;
        --inputColor: #fff;
        --inputBackground: transparent;
        --inputBorder: var(--defaultBorder);
        --inputFocusOutline: var(--defaultBorder);
        --inputFocusBorder: var(--defaultBorder);
        --inputLabelFontSize: 0.7rem;
        --checkboxColor: var(--primary);
        --checkboxCheck: 1px solid var(--formBlue2);
        --checkboxBoxShadow: none;
        --checkboxBorder: var(--inputBorder);
        --defaultButtonFontWeight: 700;
        --buttonfontWeightHover: 700;
        --defaultButtonLetterSpacing: 0.03em;
        --defaultButtonPadding: 20px 30px; 
        --defaultButtonBackground: #386aff;
        --buttonDisabledBackground: #c2c2c2;
        --buttonDisabledBorder: 2px solid #828282;
        --buttonDisabledColor: #828282;
        --buttonDisabledColor2: rgba(255,255,255,0.5);
        --defaultButtonColor: #ffffff;
        --defaultButtonBackgroundHover: #99c5ff;
        --defaultButtonColorHover: #386aff;
        --defaultButtonBorderHover: 2px solid #386aff;
        --defaultButtonBorder: 2px solid var(--defaultButtonBackground);
        --fontMicro: 10px;
        --navBarPadding: 0px 50px;
        --navBarBoxShadow: 0 0 5px #000;
        --navBarFontWeightActive: 700;
        --fontSmall: 11px;
        --headerWeight: 500;
        --fontMedium: 1.1em;
        --fontBig: 1.5em;
        --fontNormal: 1.0em;
        --bold: 700;
        --medium: 500;
        --boldSmall: 600;
        --boldSmallLetterSpacing: 0.07rem;
        /* --buttonHeight: 56px; */
        --buttonBorder: 1px solid white;
        --purpleGrey: #696980;
        --defaultInputHeight: 49px;
    }
    .p-inputtext{
        
        border-color: var(--formBlue1);
        &:focus{
        outline-color: var(--formBlue1);
        border-color: var(--formBlue1);
            /* box-shadow: 5px solid yellow; */
        }
        &:focus-visible{
        outline-color: var(--formBlue1);
            box-shadow: 0 0 0px 3px rgba(56, 106, 255, 0.25) !important;
        }
    }
   
#infoButton{
    height: 30px;
    width: 30px;
    .p-button-icon{
        font-size: 12px;
    }
}
    * {
        box-sizing: border-box;
        font-family: var(--defaultFont);
    }
    #root{
        max-width: 100%;
        height: 100%;
    }
    /* .p-button{
        background: transparent;
        padding: 20px;
        width: 100%;
        border: 1px solid #fff;
        cursor: pointer;
        color: #fff;
        &:hover{
            background: rgba(255,255,255,0.1);
        }
        &.p-button-loading{
            background: #fff;
            .p-icon-spin{
                animation: spin 4s linear infinite;
            }
            .p-button-label{
                display: none;
            }
        }
    } */
    /* .SelectContent {

} */

.SelectViewport {
  padding: 5px;
}



.SelectSeparator {
  height: 1px;
  background-color: var(--formBlue2);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: transparent;
  color: var(--violet11);
  cursor: default;
}
    .PhoneInputInput{
        height: 49px;
        color: inherit;
        font-size: 16px;
        width: 100%;
        margin: 2px;
        background: var(--underLineThemeBgd);
        outline: none;
        border: 1px solid #fff;
        box-shadow: var(--defaultInputBoxShadow);
        /* border-bottom: 1px solid #fff; */
        border-radius: 0;
        padding-left: 20px;
        padding-right: 40px;
        &:focus{
            /* border: none; */
            /* border-bottom: var(--underlineFocus); */
            box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8);
            outline: none;
        }
        ::placeholder{
            color: var(--inputPlaceHolderColor);
        }
    }
    .PhoneInput{
        width: 100%;
    }
    .PhoneInputCountry{
        background: none !important;
        display: none !important;
        visibility: hidden !important;
    }
    .PhoneInputCountrySelect{
        background-color: none;
        background: rgba(0, 23, 82,1);
        border: none;
        outline: none;
        color: var(--primary);
        display: none;
    }
    .PhoneInputCountrySelect option{
        -webkit-appearance: menulist-button;
        height: 49px;
        &:hover{
            color: rgba(0, 23, 82,1);
            background: rgba(0, 23, 82,1);
        }
        
        display: none;
    }
    @keyframes spin {
        100% { transform: rotate(360deg); } 
    }
    .gradientText{
        background: var(--formBackgroundAngled);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .listHeader{
        background: '';
        text-transform: uppercase;
        font-weight: bold;
    }
    .centerText{
        text-align: center;
    }
    .centerTextMobile{
        @media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            text-align: center;
        }
        
    }
    .pricingHeader{
        @media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            height: 10rem;
            justify-content: start;
            min-height: unset;
        }
    }
    .p50LR{
        padding-left: 50px;
        padding-right: 50px;
    }

    .homeHeader{
        width: 50%;
        max-width: 50%;
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            max-width: 100%;
            width: 100%;
            text-align: center;
        }
    }
    .homeHeaderButton{
        max-width: 250px;
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            max-width: 100%;
        }
    }

    @media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            .hideOnMobile{
                visibility: hidden;
                display: none;
            }
            .p50LRMobile{
                padding-left: 50px;
                padding-right: 50px;
            }
            .flexRowMobile{
                display: flex;
                flex-direction: row
            }   
            .wrapMobile{
            flex-wrap: wrap;
            }
            .centerTextMobile{
                text-align: center;
            }
            .noWrapOnMobile{
                flex-wrap: nowrap;
            }
            .colOnMobile{
                flex-direction: column !important;
            }
            .noPaddingMobile{
                padding: 0;
            }
        }

    /* .flexRowMobile{
        @media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            display: flex;
            flex-direction: row
        }
    } */

    /* .wrapMobile{
        @media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            flex-wrap: wrap;
        }
        
    } */

    body{
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Albert Sans', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
        background: var(--background);
        color: var(--white);
        height: 100%;
        padding-bottom: env(safe-area-inset-bottom);
    }
    @font-face {
        font-family: 'Cano';
        src: local('Cano'), url({CanoOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    html{
        margin: 0;
        font-size: 15px;
        font-family: 'Colombia';
        height: 100%;
        background: var(--background);
        /* h1{
            font-size: 3em;
            font-weight: var(--bold);
        } 
        */
        h1{
            user-select: none;
            font-size: 2em;
            font-weight: var(--bold);
            font-family: 'Playfair Display', serif;
        }
        h2{
            user-select: none;
            font-size: 1.5em;
            font-weight: var(--medium);
        }
        h4{
            font-weight: var(--boldSmall);
            font-size: .9em;
            margin: 0;
            
            /* font-weight: var(--bold);
font-size: 16px;
line-height: 1.2rem;
letter-spacing: 0.07rem;
text-transform: uppercase; */
        }
        p{
            /* font-weight: 300; */
            font-size: 1rem;
        }
        a{
            display: flex;
            text-decoration: none;
            color: var(--fontColor);
        }
    }
    @media screen and (min-width:768px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1024px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1400px) {
    html {
        font-size: 18px;
    }
}
`;