import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Flexbox from '../../Flexbox'
import InputField from '../../InputField'
import TextArea from '../../TextArea'
import Button from '../../Button'
import { Wrapper } from '../../Flexbox/Flexbox.styles'
import { validateEmail } from '../index'
import { NavLink } from 'react-router-dom'

const FlexWrapper = styled(Wrapper)`
    position: absolute;
    z-index: -1;
    visibility: hidden;
    width: 0;
    height: 0;
    display: none;
    @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            position: relative;
            z-index: unset;
            visibility: visible;
            width: 100%;
            height: 100%;
            display: flex;
        }
`;

const Link = styled(NavLink)`
all: unset;
color: #fff;
font-weight: 700;
cursor: pointer;
width: min-content;
white-space: nowrap;
grid-area: ${({ gridArea }) => gridArea};
grid-column: ${({ col }) => col};
grid-row: ${({ row }) => row};
:hover{
    text-decoration: underline;
}
`;

const FinalStep = ({ containerProps, backStep, label, fields, onChange, handleChange, placeholder, step, onKeyUp, onKeyDown, nextStep }) => {
    const [shouldShowValidators, setShouldShowValidators] = useState(false)
    const { fieldOrder, validate } = step
    const [validContinue, setValidContinue] = useState({ isValid: false, invalidField: null, shouldShow: false })
    console.log("fieldOrder", fieldOrder, fields)
    function validateFields() {
        return fieldOrder.reduce((ob, fieldKey) => {
            const field = fields[fieldKey]
            const { name, type } = field
            const { isValid } = ob //setValidContinue(prev => ({ ...prev, shouldShow: true }))
            if (!isValid)
                return ob
            if (name === 'phoneNumber') {
                if (field.value['phoneNumber'] === '' && field.value['extension'] === '')
                    return ob
                if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(field.value['phoneNumber']))
                    ob = { ...ob, isValid: false, invalidField: 'Invalid phone number.' }
                if (field.value['extension'] === '')
                    return ob
                if (/^[0-9]+$/.test(field.value['extension']))
                    return ob
            } else if (name === 'email') {
                if (!validateEmail(field.value))
                    ob = { ...ob, isValid: false, invalidField: 'Please provide a valid email.' }
            }
            return ob
            //field.value
        }, { isValid: true, invalidField: null, shouldShow: false })
    }
    useEffect(() => {
        setValidContinue(validateFields())
    }, [fields])
    console.log("valid continue: ", validContinue)
    return (
        <Flexbox
            // background="#fff"
            // color="#000"
            column gap="20px" width="100%" className="noMarginForText left"
            //  border="1px solid #fff" 
            padding="50px" maxWidth="690px"
            {...containerProps}>
            {/* <img src={connectWith} style={{ position: 'relative', height: '100%', width: '100%', maxWidth: '150px' }} /> */}
            <h3 >How can we contact you?</h3>
            {/* <h2 >Your attorney will reach out as soon as possible.</h2> */}
            {/* <p>werlkjlkjkjlkjkjjklj</p> */}
            {
                fieldOrder.map(fieldKey => {
                    const field = fields[fieldKey]
                    const { name, type } = field
                    console.log("field", field)
                    if (name === 'phoneNumber')
                        return (
                            <>
                                <h4 style={{ margin: '0', alignSelf: 'start' }} className="left">
                                    {field.label}
                                </h4>

                                <Flexbox gap="10px" width="100%">
                                    <Flexbox flexGrow="2" flexBasis="70%">
                                        <InputField
                                            // onKeyPress={continueKey}
                                            type={type ? type : name === 'email' ? "email" : 'text'}
                                            onChange={handleChange(`${name}`)}
                                            // onChange={function (_val) { step.onChange(_val, 'phoneNumber') }}
                                            name={name}
                                            placeholder={field.placeholder['phoneNumber']}
                                            marginBottom={'20px'}
                                            value={name === 'phoneNumber' ? field.value[name] : field.value}
                                            width="100%"
                                            onKeyDown={onKeyDown(`${name}`)}
                                            onKeyUp={onKeyUp}
                                            uppercaseOff={true}
                                            border="none; border-bottom: 1px solid #fff"
                                            showValidationCheckmark={(shouldShowValidators && !field['isValid']) || field['shouldShow']}

                                            fontSize="16px"
                                            labelTextAlign="left"
                                            labelFontSize="14px"
                                            focusOutline="none" padding="0 40px 0 20px"
                                            outline="none"
                                            focusBorder="none; border-bottom: 2px solid #fff"
                                            flexDirection="column"
                                            background="rgba(255,255,255,0.1)"
                                        />
                                    </Flexbox>
                                    <Flexbox fontSize="12px">
                                        ext
                                    </Flexbox>
                                    <Flexbox flexShrink="2" flexGrow="1" flexBasis="30%">
                                        <InputField
                                            // onKeyPress={continueKey}
                                            type={type ? type : name === 'email' ? "email" : 'text'}
                                            // onChange={handleChange(`${name}`)}
                                            onChange={function (_val) { field.onChange(_val, 'extension') }}
                                            name={'extension'}
                                            placeholder={field.placeholder['extension']}
                                            marginBottom={'20px'}
                                            value={field.value['extension']}
                                            width="100%"
                                            // onKeyDown={onKeyDown(`${name}`)}
                                            onKeyUp={onKeyUp}
                                            uppercaseOff={true}
                                            border="none; border-bottom: 1px solid #fff"
                                            showValidationCheckmark={(shouldShowValidators && !field['isValid']) || field['shouldShow']}

                                            fontSize="16px"
                                            labelTextAlign="left"
                                            labelFontSize="14px"
                                            focusOutline="none" padding="0 40px 0 20px"
                                            outline="none"
                                            focusBorder="none; border-bottom: 2px solid #fff"
                                            flexDirection="column"
                                            background="rgba(255,255,255,0.1)"
                                        />
                                    </Flexbox>
                                </Flexbox>
                            </>
                        )
                    if (name !== 'additionalDetails')
                        return (
                            <>
                                <h4 style={{ margin: '0', alignSelf: 'start' }} className="left">
                                    {field.label}
                                </h4>
                                <Flexbox gap="10px" width="100%">

                                    <Flexbox flexGrow="2" flexBasis="100%" width="100%">
                                        <InputField
                                            // inputPlaceHolderColor="#9c9c9c"
                                            // color="#000"
                                            // border="1px solid #000"
                                            // focusOutline="1px solid var(--formBlue1)"
                                            // onKeyPress={continueKey}
                                            type={type ? type : name === 'email' ? "email" : 'text'}
                                            onChange={function (event) {
                                                console.log("here::::::::")
                                                field.onChange(event)
                                            }}
                                            // onChange={function (_val) { step.onChange(_val, 'phoneNumber') }}
                                            name={name}
                                            placeholder={field.placeholder}
                                            marginBottom={'20px'}
                                            value={field.value}
                                            width="100%"
                                            onKeyDown={onKeyDown(`${name}`)}
                                            onKeyUp={onKeyUp}
                                            uppercaseOff={true}
                                            showValidationCheckmark={(shouldShowValidators && !field['isValid']) || field['shouldShow']}

                                            border="none; border-bottom: 1px solid #fff"
                                            fontSize="16px"
                                            labelTextAlign="left"
                                            labelFontSize="14px"
                                            focusOutline="none" padding="0 40px 0 20px"
                                            outline="none"
                                            focusBorder="none; border-bottom: 2px solid #fff"
                                            flexDirection="column"
                                            background="rgba(255,255,255,0.1)"
                                        />
                                    </Flexbox>

                                </Flexbox>
                            </>
                        )
                    else
                        return (
                            <>
                                <h4 style={{ margin: '0', alignSelf: 'start' }}>
                                    {field.label}
                                </h4>
                                <TextArea
                                    {...step}
                                    name={name}
                                    type={type}
                                    value={field.value}

                                    fontSize="16px"
                                    // inputPlaceHolderColor="#9c9c9c"
                                    // color="#000"
                                    // border="1px solid #000"
                                    // focusOutline="1px solid var(--formBlue1)"
                                    onChange={function (event) {
                                        console.log("here::::::::")
                                        field.onChange(event)
                                    }}
                                    background="rgba(255,255,255,0.1)"
                                    border="none; border-bottom: 1px solid #fff"
                                    padding="10px 35px 10px 20px"
                                    focusBorder="none; border-bottom: 2px solid #fff"
                                    focusOutline="none"
                                />
                            </>
                        )
                })
            }
            <Flexbox width="100%" gap="10px" flexDirection="column" alignItems="start" flexWrapMobile="nowrap">
                <Flexbox width="100%" gap="10px" flexDirection="row" alignItems="start" flexWrapMobile="nowrap">


                    <FlexWrapper>
                        <Button

                            // background="var(--formBackground)"
                            background="rgba(224, 245, 255,0.25)"
                            color="#fff"
                            colorHover="#376afe"
                            paddingRight="0"
                            paddingLeft="0"
                            border="2px solid #fff"
                            text="Back"
                            letterSpacing="0.05em"
                            bgdHover="#fff"
                            onClick={backStep} width="100%" />
                    </FlexWrapper>

                    <Button
                        background={validContinue['isValid'] ? "#fff" : "rgba(224, 245,255, 0.25)"}
                        // background="var(--formBackground)"
                        color={validContinue['isValid'] ? "var(--defaultButtonBackground)" : "#ffffff"}
                        colorHover="#fff"
                        paddingRight="0"
                        paddingLeft="0"
                        border="2px solid #fff"
                        borderHover="2px solid #fff"
                        text="Submit"
                        // disabled={validContinue['isValid']}
                        cursor={validContinue['isValid'] ? 'pointer' : 'not-allowed'}
                        className={validContinue['isValid'] ? '' : 'disabled'}
                        letterSpacing="0.05em"
                        bgdHover="var(--defaultButtonBackground)"
                        bgdDisabled="rgba(224, 245, 255,0.25) !important"
                        onClick={async function (e) {
                            console.log("validContinue['isValid']", validContinue['isValid'])
                            setValidContinue(prev => ({ ...prev, shouldShow: true }))
                            setShouldShowValidators(true)
                            await nextStep(e)
                        }}
                        width="100%" />
                </Flexbox>
                <p style={{
                    fontSize: '12px',
                    margin: '0',
                    textAlign: 'left'
                }}>
                    By using our service and providing your personal contact information, you agree that we may contact you at the number and/or email provided. Submission of this form confirms you have read and agree to the <Link to="/privacy-policy" target="_blank">Privacy Policy</Link> and <Link to="/terms" target="_blank">Terms and Conditions</Link>.
                </p>
                <Flexbox height="12px" fontSize="10px" width="100%">{
                    (!validContinue['isValid'] && validContinue['shouldShow']) &&
                    validContinue['invalidField']
                }</Flexbox>
            </Flexbox>

        </Flexbox>
    )
}

export default FinalStep