import React, { useEffect, useState } from 'react'
import InputField from './InputField'
import PageContentContainer from './PageContentContainer'
import TextArea from './TextArea'
import RadioButtons from './RadioButtons2'
import Flexbox from './Flexbox'
// import Button from './Button'
import MultiSelect from './MultiSelect'
import { Checkbox } from 'primereact/checkbox';
import API from '../API'

import { Button } from './Navigation';
import CheckmarkValidate from './CheckmarkValidate'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { InputText } from 'primereact/inputtext';

const Login = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { login, loadingUser, currentUser } = useAuth()
    useEffect(() => {
        if (currentUser) {
            const navTo =
                location.state !== undefined && location.state !== null && location.state.from !== undefined ?
                    location.state.from : '/';
            // if (navTo !== '/')
            navigate(navTo)
        } else if (currentUser === null && loadingUser === false) {
            // setLoading(false)
        }
    }, [currentUser, loadingUser])

    const [state, setState] = useState({
        serverResponse: '',
        loading: false,
        success: false,
        isValid: false,
        shouldShow: false,
        sections: {
            'section-1': {
                fields: ["field-1", "field-2"]
            }
        },
        fields: {
            "field-1": {
                id: "field-1",
                name: "email",
                type: "input",
                width: '100%',
                value: "",
                placeholder: "Email",
                label: "Email",
                isRequired: true,
                validate: function (v = this.value) {
                    return v.trim().length > 0
                },
                get isValid() {
                    return this.validate(this.value)
                },
            },
            "field-2": {
                id: "field-2",
                name: "password",
                type: "password",
                width: '100%',
                value: "",
                placeholder: "Password",
                label: "Password",
                isRequired: true,
                validate: function (v = this.value) {
                    return v.trim().length > 0
                },
                get isValid() {
                    return this.validate(this.value)
                },
            },


        }
    })
    const { shouldShow, isValid, loading, success, fields, serverResponse } = state
    const getFieldFromName = (name) => {
        return Object.keys(fields).find(field => {
            return fields[field].name.toLowerCase() === name.toLowerCase()
        })
    }
    const updateObj = (field, v) => {
        if (field.type === 'radio')
            return { value: v, selected: v }
        else {
            let returnObj = { value: v }
            if (typeof (field['validate']) === 'function') {
                returnObj['isValid'] = field['validate'](v)
            }
            return returnObj
        }

    }

    const onChange = (fieldId, e, updateKey = null) => {
        let v;
        if (typeof (e) === 'string')
            v = e
        else
            v = e?.target?.value || ''
        setState(prev => ({
            ...prev,
            fields: {
                ...prev.fields,
                [fieldId]: {
                    ...prev.fields[fieldId],
                    ...updateObj(fields[fieldId], v, updateKey)
                }
            }
        }))
    }
    const multiSelect = (fieldId, v) => {
        setState(prev => {
            let value = [...prev['fields'][fieldId].value, v]
                .reduce((a, b) => {
                    if (a.includes(b))
                        a = a.filter(it => it !== b)
                    else
                        a.push(b)
                    return a
                }, [])
            return ({
                ...prev,
                fields: {
                    ...prev.fields,
                    [fieldId]: {
                        ...prev.fields[fieldId],
                        value,
                        selected: v
                    }
                }
            })
        })
    }
    const validateFields = (fields = state.fields) => {

        return Object.keys(fields).reduce((a, fieldKey) => {
            if (!fields[fieldKey].isRequired || a === false || typeof (fields[fieldKey].validate) !== 'function')
                return a
            else
                return fields[fieldKey].validate()

        }, true)

    }

    return (
        <PageContentContainer margin="calc(var(--navBarHeight) * -1) 0 0" column background="var(--formBackground)" padding="50px 0 0" maxHeight="100%" height="100vh">

            <Flexbox background="#fff" padding="30px" border="1px solid #fff" column alignItems="start" width="100%" maxWidth="600px" margin="auto" boxShadow="0 0 3px #000000c3" borderRadius="15px">
                <h1 style={{ color: '#424242' }}>Welcome Back.</h1>
                {
                    Object.keys(fields).map(fieldKey => {
                        const field = fields[fieldKey]
                        let _shouldShow = false
                        if (field['isValid'] !== undefined) {
                            _shouldShow = !field['isValid'] && shouldShow
                        }
                        field['onChange'] = (e) => {
                            onChange(fieldKey, e)
                        }
                        if (field.type === 'input' || field.type === 'password')
                            return (<InputField {...field} showValidationCheckmark={_shouldShow}
                                focusBgd="#94cdff42"
                                focusBorder='none;border-bottom:1px solid #000'
                                inputPlaceHolderColor="#9d9b9b"
                                focusColor="#000"
                                labelFontSize=".8rem"
                                background="#fff"
                                color="var(--formBlue1)"
                                stroke="var(--formBlue1)"
                                border='none;border-bottom:1px solid var(--formBlue1)'
                                key={fieldKey}
                            />)

                    })
                }
                <Button
                    // borderC="#fff"
                    // bgdH="rgba(255,255,255,0.1)"
                    onClick={async () => {
                        let res = {};
                        try {
                            if (!validateFields()) {
                                setState(prev => ({
                                    ...prev,
                                    shouldShow: true,
                                    serverResponse: <span style={{ alignItems: 'center', display: 'flex', position: 'relative' }}>Please complete fields marked with a <CheckmarkValidate
                                        position="relative"
                                        top="unset"
                                        stroke="var(--formBlue1)"
                                        right="-3px"
                                        shouldShow={true} isValid={false} /></span>
                                }))
                            } else {
                                if (!loading && !success) {
                                    setState(prev => ({
                                        ...prev,
                                        loading: true,
                                        shouldShow: false,
                                        serverResponse: ""
                                    }))
                                    let res = await login(fields[getFieldFromName('email')].value, fields[getFieldFromName('password')].value)
                                    setState(prev => ({
                                        ...prev,
                                        loading: false,
                                        success: res['success'],
                                        serverResponse: res['msg']
                                    }))
                                }

                            }


                        } catch (error) {
                            let msg = 'An error occurred.'
                            if (error.response && error.response.data)
                                msg = error.response.data?.msg || msg
                            setState(prev => ({
                                ...prev,
                                loading: false,
                                success: false, //res['success'],
                                serverResponse: msg
                            }))
                        }
                        
                    }}
                    loading={loading}
                    width="100%"
                    padding="1em 0"
                    label="Submit" margin="20px 0 0" />
                <Flexbox><Button label="Forgot Password" size="small"
                    fontSize="12px"
                    padding="0.4375em 0"
                    text onClick={async () => {
                        let x = await API.forgotPassword(fields[getFieldFromName('email')].value)
                        setState(prev => ({
                            ...prev,
                            loading: false,
                            success: false, //res['success'],
                            serverResponse: typeof (x) === 'string' ? x : '' //res['msg']
                        }))
                    }} /></Flexbox>
                <p style={{ color: 'var(--formBlue1)' }}>{serverResponse}</p>
            </Flexbox>
        </PageContentContainer>
    )
}

export default Login