import styled from 'styled-components'

export const List = styled.ul`
display: flex;
padding: 0;
width: 100%;
flex-wrap: wrap;
gap: ${({ gap }) => (gap)};
li:not(:last-child):not(:first-child){
    /* margin: 0 10px; */
}
@media screen and (max-width:768px) {
    flex-direction: column;
}
`;

export const Text = styled.p`
color: var(--secondary);

`;

export const ListItem = styled.li`
    /* height: 40px; */
    list-style: none;
    text-align: left;
    flex-grow: 1;
    width: 100%;
    flex-basis: 100%;
    p{
        font-size: 0.8rem;
        margin: 0;
        /* color: var(--secondary); */
    }
    h3{
        margin: 0;
    }
    >div{
        gap: ${({ gap }) => (gap)};
    }
    
`;

export const Icon = styled.img`
position: relative;
height: auto;
width: auto;
max-height: 400px;
/* max-width: 120px; */
object-fit: contain;
`;

export const ComponentWrapper = styled.div`
position: relative;
height: 100%;
width: 100%;
/* max-width: 120px; */
max-height: 80px;
object-fit: contain;
display: flex;
justify-content: center;

`;

export const H2 = styled.h1`
color: ${({ color }) => color};
transition: color 0.5s ease-in-out;
`