import styled from 'styled-components';


export const LabelStyled = styled.label`
    position: ${({ position = 'relative' }) => position};
    font-weight: ${props => props.labelWeight ? props.labelWeight : ''};
    font-size:  ${props => props.labelFontSize ? props.labelFontSize : '1em'};
    text-transform:  ${props => props.labelTextTransform ? props.labelTextTransform : 'none'};
    letter-spacing: ${props => props.labelTextTransform ? '0.1rem' : ''};
    display:inline-block;
    text-align: ${({ labelTextAlign }) => labelTextAlign};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};
    width: ${({ width }) => width};
    color: ${({color}) => color};
`;


export const InputStyled = styled.input`
    height: 49px;
    margin: 2px;
    caret-color: ${({ color = "#fff" }) => color};
    padding: ${({ padding }) => padding || '0 20px'};
    width: 100%;
    color: ${({ color = '#fff' }) => color};
    background: ${({ background }) => background || "var(--inputBackground)"};
    border: ${({ border }) => border || 'var(--inputBorder)'};
    outline: ${({ outline }) => outline || 'var(--inputOutline)'};
    position: ${({ position = 'relative' }) => position};
    transition: ${({ transition }) => transition};
    min-width:${({ minWidth }) => minWidth};
    font-size: ${({ fontSize }) => fontSize};
    text-align: ${({ textAlign }) => textAlign};
    max-width:${({ maxWidth }) => maxWidth};
    border-radius: 0;
    ::placeholder{
        color: ${({ inputPlaceHolderColor = 'var(--inputPlaceHolderColor)' }) => inputPlaceHolderColor};
    }


    :focus{
        outline: ${({ focusOutline }) => focusOutline || 'var(--inputFocusOutline)'};
        border: ${({ focusBorder }) => focusBorder || 'var(--inputFocusBorder)'};
        background: ${({focusBgd}) => focusBgd};
        color: ${({focusColor}) => focusColor};
    }

`;

export const Wrapper = styled.div`
z-index: ${({ zIndex }) => zIndex};
    position: ${({ position = 'relative' }) => position};
    width: ${props => props.width ? props.width : props.wrapperWidth ? props.wrapperWidth : ''};
    display: ${({ display }) => display};
    flex-direction: ${({ flexDirection }) => flexDirection};
    justify-content: ${({ justifyContent }) => justifyContent};
    align-items: ${({ alignItems }) => alignItems};
`;

export const InnerWrapper = styled.div`
z-index: ${({ zIndex }) => zIndex};
  position: ${({ position = 'relative' }) => position};
  margin-top: ${props => props.marginTop ? props.marginTop : '0'};
  width: ${props => props.width ? props.width : '100%'};
    display: flex;
    align-items: center;
`;

export const DollarSign = styled.div`
user-select: none;
position: absolute;
left: 1rem;
z-index: 1;

color: ${({ color = '#fff' }) => color};
`;

export const AlertIconWrapper = styled.div`
position: absolute;
right: 5px;
`;

export const SearchIcon = styled.img`
height: 15px;
width: 15px;
cursor: pointer;
opacity: ${props => props.opacity ? props.opacity : '0.25'};
position: absolute;
right: 1rem;
user-select: none;
`;