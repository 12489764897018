import React, { useState, useEffect, useRef } from 'react'

import API from '../../API'
import Flexbox from '../Flexbox'
import FadeIn from '../FadeIn'
import Icon from '../Icon'
import Footer from '../Footer'

import addIcon from '../../images/addIcon.svg'

import anime from 'animejs'

const Collapsible = ({ qa, index }) => {
    const [open, setOpen] = useState(false)
    const elementRef = useRef(null)
    const collapseSection = (element) => {
        if (open)
            anime({
                targets: `.qa${index}`,
                height: [0, '100%'], // from 100 to 250
                delay: 500,
                // direction: 'alternate',
                loop: false
            });
        else
            anime({
                targets: `.qa${index}`,
                height: [0, '100%'], // from 100 to 250
                delay: 500,
                // direction: 'alternate',
                loop: false
            });
        // var sectionHeight = element.scrollHeight;
        // if (!open)
        //     requestAnimationFrame(function () {
        //         element.style.height = 0 + 'px';
        //     });
        // if (open)
        //     requestAnimationFrame(function () {
        //         element.style.height = sectionHeight + 'px';
        //         element.style.transition = "";

        //         // on the next frame (as soon as the previous style change has taken effect),
        //         // have the element transition to height: 0

        //     });
    }
    // useEffect(() => {
    //     if (elementRef.current) {
    //         console.log("changing height")
    //         var sectionHeight = elementRef.current.scrollHeight;
    //         if (open)
    //             elementRef.current.height = sectionHeight + 'px';
    //         else
    //             elementRef.current.height = '0px';
    //     }
    // }, [open])
    return (
        // <FadeIn padding="20px" onClick={() => { setOpen(!open) }}
        //     column alignItems="start" flexGrow="1" flexBasis="100%" justifyContent="start">
        <Flexbox
            padding="20px 0 0" onClick={() => {
                setOpen(!open)
            }}
            column alignItems="start" flexGrow="1" flexBasis="100%" justifyContent="start">
            <Flexbox>
                <h3 style={{ margin: '0', fontWeight: '500' }} >{qa['question']}</h3>
                <Icon src={addIcon} transition="all 0.25s ease-in" height="25px" width="25px" transform={open ? "rotate(45deg)" : "rotate(0deg)"} />
            </Flexbox>

            <Flexbox
                className={`qa${index}`}
                refID={elementRef}
                column
                transition="all 1s ease-in"
                alignItems={"start"}
                // flex={open ? '1' : '0'}
                transitionDelay="0"
                height={"auto"}
                maxHeight={open ? "1800px" : "0px"}
                // height="auto"
                overflow={"hidden"}>
                {typeof (qa['answer']) === 'string' && qa['answer'].split('\\n').map((el, index) =>
                    <p key={index}
                        style={{
                            // color: 'var(--secondary)',
                            fontSize: '1.0rem',
                            textAlign: 'left'
                            // textAlign: 'center'
                        }}>
                        {el}
                    </p>
                )}
                {
                    typeof (qa['answer']) !== 'string' &&
                    <p
                        style={{
                            // color: 'var(--secondary)',
                            fontSize: '1.0rem',
                            textAlign: 'left'
                            // textAlign: 'center'
                        }}>
                        {qa['answer']}
                    </p>
                }

            </Flexbox>
        </Flexbox>
    )
}

const FAQ = ({ isHome = false }) => {
    const [faq, setFAQ] = useState([])
    useEffect(() => {
        const fetchFAQ = async () => {
            // let _faq = await API.getFAQ()
            let _faq = [
               
                {
                    question: 'When can I expect leads?',
                    answer: <>
                        We typically launch your ad campaign within 1-2 business days after receipt of payment and will greatly optimize the campaign over the next few weeks (and will continue to optimize on an ongoing basis).  You may receive leads within a few days after the ad campaign is active, especially if you have chosen popular practice areas in high population regions. <br /><br />If your order requires software development such as custom websites, intake forms, lead criteria, CRM integration or creation of new ad campaigns, please inquire.
                    </>,
                },
                {
                    question: 'What determines how many leads I receive per week/month?',
                    answer:
                        <>
                            The primary factor is the population size of the geographic areas you select.  High population areas will generate more leads.  Campaign optimization over time may also greatly increase your lead volume.
                            <br /><br />
                            However, you can buy as many or few leads as you like. We only generate as many leads as you want to buy, so there is no risk of getting stuck with more leads than your budget allows.
                        </>,
                },
                {
                    question: 'How do you deliver the leads?',
                    answer:
                        <>
                            We send leads via email but can accomodate requests to send via SMS text or other methods. We can also integrate lead delivery with your current sales or lead management software platform.
                        </>,
                },
                {
                    question: 'Returns?',
                    answer:
                        <>
                            If a lead is invalid, we will replace it at no charge.
                        </>,
                },
                {
                    question: 'Minimums?',
                    answer:
                        <>
                            To make getting started as easy as possible, in most cases there is no minimum order size.  However, we strongly recommend you try at least 10 leads or your sample size will be too small to judge lead quality.
                        </>,
                },
                {
                    question: 'Contracts?',
                    answer:
                        <>
                            Never. Stop anytime.
                        </>,
                },
            ]
            // _faq = _faq.map(item => {
            //     // let answer = item['answer'].split('\\n')
            //     // answer = answer.map((el, index) =>

            //     //     <p key={index} style={{
            //     //         color: 'var(--secondary)', fontSize: '1.2rem',
            //     //         // textAlign: 'center'
            //     //     }}>
            //     //         {el}
            //     //     </p>

            //     // )
            //     return ({
            //         question: item['question'],
            //         answer: item['answer'],
            //         // answer

            //     })
            // })
            setFAQ(_faq)
        }
        fetchFAQ()
    }, [])
    console.log("faq", faq)
    return (
        <Flexbox column position="relative"
            minHeight={!isHome ? "calc(100vh - var(--navBarHeight))" : ''}
            margin={!isHome ? 'calc(var(--navBarHeight) * -1 ) 0 0' : undefined}
            background={!isHome ? "var(--formBackgroundAngled)" : undefined}
            alignItems="center">
            <Flexbox 
            
            padding={!isHome ? 'calc(var(--navBarHeight) * 1) 50px 50px' : '0 50px 50px'}
                height="100%"
                // minHeight={!isHome ? "calc(100vh - var(--navBarHeight))" : ''}
                position="relative"
                // overflow={!isHome ? "hidden auto" : undefined} 
                column alignItems="center"
            // background="var(--formBackgroundAngled)" color="#fff" 
            >
                <h1>F.A.Q.</h1>
                {/* <p>lorem</p> */}
                <Flexbox gap="0px" flexWrap="wrap" height="min-content" alignItems="start" maxWidth="1000px">

                    {
                        faq.map((it, index) => {
                            return (
                                <Collapsible qa={it} key={index} index={index} />
                            )
                        })
                    }

                </Flexbox>

            </Flexbox>
           
        </Flexbox>
    )
}

export default FAQ