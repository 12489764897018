import React, { useState, useEffect } from 'react'
import InputField, { Label } from './InputField'
import PageContentContainer from './PageContentContainer'
import TextArea from './TextArea'
import RadioButtons from './RadioButtons2'
import Flexbox from './Flexbox'
// import Button from './Button'
import MultiSelect from './MultiSelect'
import { Checkbox } from 'primereact/checkbox';
import API from '../API'
import RadixDropdown from './RadixDropdown'
import { Button } from './Navigation';
import CheckmarkValidate from './CheckmarkValidate'
import PhoneInput from './PhoneInput'
import { validateEmail } from './Form'


const Dispute = () => {
    const [state, setState] = useState({
        serverResponse: '',
        loading: false,
        success: false,
        isValid: false,
        shouldShow: false,
        sections: {
            'section-1': {
                fields: ["field-1", "field-2"]
            }
        },
        fields: {
            "field-1": {
                id: "field-1",
                name: "leadId",
                type: "input",
                width: '100%',
                value: "",
                placeholder: "Lead Id",
                label: "Lead Id",
                isRequired: false,
                // validate: function (v = this.value) {
                //     return v.trim().length > 0
                // },
                // get isValid() {
                //     return this.validate(this.value)
                // },
            },
            "field-5": {
                id: "field-5",
                name: "phoneNumber",
                type: "phone",
                width: '100%',
                value: "",
                placeholder: "Phone Number",
                label: "Phone Number*",
                isRequired: true,
                validate: function (v = this.value) {
                    return v.trim().length > 0
                },
                get isValid() {
                    return this.validate(this.value)
                },
            },
            "field-6": {
                id: "field-6",
                name: "email",
                type: "input",
                width: '100%',
                value: "",
                placeholder: "Email",
                label: "Email*",
                isRequired: true,
                validate: function (v = this.value) {
                    return validateEmail(v)
                },
                get isValid() {
                    return this.validate(this.value)
                },
            },
            "field-2": {
                id: "field-2",
                name: 'reasonForDispute',
                maxWidth: '400px',
                valueIsSelectedKey: "id",
                displayKey: "innerText",
                additionalUpdateKeys: [{
                    mapFrom: "innerText",
                    mapTo: "displayValue"
                }],
                dataKey: "id",
                type: 'dropdown',
                shouldShow: false,
                showDropdown: false,
                showLabel: true,
                isRequired: true,
                options: {},
                label: "Why are you disputing this lead?",
                wrapperWidth: '100%',
                placeholder: '--',
                get value() { return ("--") },
                validate: function (v = this.value) {
                    return v !== "--"
                },
                get isValid() {
                    return this.validate(this.value)
                },
            },
            "field-4": {
                id: "field-4",
                name: "methodsOfContact",
                type: "multiselect",
                label: "What methods of contact were attempted?",
                value: [],
                options: [
                    "Phone Call",
                    "Email",],
            },
            "field-3": {
                id: "field-3",
                name: 'comments',
                width: '100%',
                maxWidth: 'unset',
                type: 'textArea',
                label: "Comments",
                value: ""
            },
        }
    })
    const { shouldShow, isValid, loading, success, fields, serverResponse } = state

    const getFieldIdByName = (name, _fields = fields) => Object.keys(_fields).find(field => _fields[field].name === name)

    useEffect(() => {
        async function getDisputeReasons() {
            const disputes = await API.getDisputeOptions()
            const { disputeReasons } = disputes
            if (Array.isArray(disputeReasons)) {
                setState(prev => ({
                    ...prev,
                    fields: {
                        ...prev.fields,
                        "field-2": {
                            ...prev.fields['field-2'],
                            options: Object.fromEntries(disputeReasons.map((item, index) => {
                                const { options, _id } = item
                                return ([`group-${index + 1}`, {
                                    groupLabel: _id,
                                    options: options.map(it => {
                                        return ({ ...it, id: it._id, value: it._id, innerText: it.displayName, label: it.displayName })
                                    })
                                }])
                            }))
                        }
                    }
                }))
            }

        }
        getDisputeReasons()
    }, [])

    const updateObj = (field, v, additionalUpdateKeys) => {
        if (field.type === 'radio')
            return { value: v, selected: v }
        else {
            let returnObj = { value: typeof (v) === 'string' ? v : v['value'] }
            if (typeof (field['validate']) === 'function') {
                returnObj['isValid'] = field['validate'](v['value'])
            }
            if (typeof (v) === 'object' && v !== null) {
                // if (v.additionalRequiredInfo){

                // }
            }
            if (Array.isArray(additionalUpdateKeys)) {
                for (let index = 0; index < additionalUpdateKeys.length; index++) {
                    const { mapFrom, mapTo } = additionalUpdateKeys[index];
                    console.log("mapFrom", mapFrom, mapTo, field)
                    returnObj[mapTo] = v[mapFrom]
                }
            }
            return returnObj
        }

    }

    const onChange = (fieldId, e, updateKey = null) => {
        let updateKeys = []
        setState(prev => {
            let v;
            if (typeof (e) === 'string')
                v = e
            else if (prev.fields[fieldId].additionalUpdateKeys) {
                updateKeys = prev.fields[fieldId].additionalUpdateKeys
                v = e
            }
            else
                v = e?.target?.value || e?.value || ''
            return ({
                ...prev,
                success: false,
                fields: {
                    ...prev.fields,
                    [fieldId]: {
                        ...prev.fields[fieldId],
                        ...updateObj(fields[fieldId], v, updateKeys)
                    }
                }
            })
        })
    }
    const multiSelect = (fieldId, v) => {
        setState(prev => {
            let value = [...prev['fields'][fieldId].value, v]
                .reduce((a, b) => {
                    if (a.includes(b))
                        a = a.filter(it => it !== b)
                    else
                        a.push(b)
                    return a
                }, [])
            return ({
                ...prev,
                success: false,
                fields: {
                    ...prev.fields,
                    [fieldId]: {
                        ...prev.fields[fieldId],
                        value,
                        selected: v
                    }
                }
            })
        })
    }
    const validateFields = (fields = state.fields) => {

        return Object.keys(fields).reduce((a, fieldKey) => {
            if (!fields[fieldKey].isRequired || a === false || typeof (fields[fieldKey].validate) !== 'function')
                return a
            else
                return fields[fieldKey].validate()

        }, true)

    }
    console.log(fields)
    return (
        <PageContentContainer margin="calc(var(--navBarHeight) * -1) 0 0" column background="var(--formBackground)" padding="50px 0 25px" maxHeight="100%" height="100%">
            <Flexbox background="#fff" padding="30px" border="1px solid #fff" column alignItems="start" width="95%" maxWidth="800px" margin="auto" boxShadow="0 0 3px #000000c3" borderRadius="15px" color='#424242'>

                <h1 style={{ color: '#424242' }}>Dispute Lead</h1>
                {
                    Object.keys(fields).map(fieldKey => {
                        const field = fields[fieldKey]
                        let _shouldShow = false
                        if (field['isValid'] !== undefined) {
                            _shouldShow = !field['isValid'] && shouldShow
                        }
                        field['onChange'] = (e) => {
                            onChange(fieldKey, e)
                        }
                        console.log(fieldKey, field['isValid'], _shouldShow)
                        if (field['type'] === 'radio') {
                            field['onClick'] = field['onChange']
                        }
                        if (field.type === 'dropdown')
                            return (<RadixDropdown {...field}
                                color="#000"
                                width="fit-content"
                                stroke="var(--formBlue1)"
                                labelFontSize=".8rem"
                                onValueChange={(e) => {
                                    console.log("::::::::::::::", e, e?.target, e?.value, fieldKey)
                                    onChange(fieldKey, e)
                                }}
                                showValidationCheckmark={_shouldShow}
                            />)
                        if (field.type === 'phone')
                            return (<>
                                <PhoneInput
                                    padding={"0 40px 0 20px"}
                                    {...field}
                                    showValidationCheckmark={_shouldShow}
                                    showLabel={true}
                                    focusBgd="#94cdff42"
                                    focusBorder='none;border-bottom:1px solid #000'
                                    inputPlaceHolderColor="#9d9b9b"
                                    color="#000"
                                    labelFontSize=".8rem"
                                    background="#fff"
                                    focusColor="var(--formBlue1)"
                                    stroke="var(--formBlue1)"
                                    border='none;border-bottom:1px solid var(--formBlue1)'
                                    key={fieldKey} />
                            </>)
                        if (field.type === 'input')
                            return (
                                <InputField {...field} showValidationCheckmark={_shouldShow}
                                    focusBgd="#94cdff42"
                                    focusBorder='none;border-bottom:1px solid #000'
                                    inputPlaceHolderColor="#9d9b9b"
                                    color="#000"
                                    labelFontSize=".8rem"
                                    background="#fff"
                                    focusColor="var(--formBlue1)"
                                    stroke="var(--formBlue1)"
                                    border='none;border-bottom:1px solid var(--formBlue1)'
                                    key={fieldKey}
                                />)
                        else if (field.type === 'radio')
                            return (<RadioButtons {...field}
                                focusBgd="#94cdff42"
                                focusBorder='none;border-bottom:1px solid #000'
                                inputPlaceHolderColor="#9d9b9b"
                                color="#000"
                                labelFontSize=".8rem"
                                fontSize="16px"
                                // uncheckedBgd="#d5d5d5"
                                focusColor="var(--formBlue1)"
                                maxWidth="100%"
                                stroke="var(--formBlue1)"
                                checkedBorder='2px solid var(--formBlue1)'
                                uncheckedBorder='2px solid #9d9b9b'
                                key={fieldKey}
                            // inputFieldOnChange={(e, option) => {
                            //     setState(prev => ({
                            //         ...prev,
                            //         fields: {
                            //             ...prev.fields,
                            //             [fieldKey]: {
                            //                 ...prev.fields[fieldKey],
                            //             }
                            //         }
                            //     }))
                            //     onChange(fieldKey, e, 'inputField')
                            // }}
                            />)
                        else if (field.type === 'multiselect')
                            return (<MultiSelect {...field} onSelect={(v) => multiSelect(fieldKey, v)}
                                // focusBgd="#94cdff42"
                                // focusBorder='none;border-bottom:1px solid #000'
                                // inputPlaceHolderColor="#9d9b9b"
                                color="#000"
                                labelFontSize=".8rem"
                                // uncheckedBgd="#d5d5d5"
                                // focusColor="var(--formBlue1)"
                                // stroke="var(--formBlue1)"
                                borderUnchecked='2px solid #9d9b9b'
                                borderChecked='1px solid var(--formBlue1)'
                                // uncheckedBorder='2px solid #9d9b9b'
                                key={fieldKey}
                            />)
                        else
                            return <TextArea {...field}
                                focusBgd="#94cdff42"
                                focusBorder='1px solid #000'
                                inputPlaceHolderColor="#1c0e0e"
                                color="#000"
                                labelFontSize=".8rem"
                                background="#fff"
                                focusColor="var(--formBlue1)"
                                stroke="var(--formBlue1)"
                                border='1px solid var(--formBlue1)'
                                key={fieldKey} />
                    })
                }
                <Button
                    borderC="#fff"
                    onClick={async () => {
                        console.log("validating fields", validateFields())
                        let res = {};
                        try {
                            if (!validateFields()) {
                                setState(prev => ({
                                    ...prev,
                                    shouldShow: true,
                                    serverResponse: <span style={{ alignItems: 'center', display: 'flex', position: 'relative' }}>Please complete fields marked with a <CheckmarkValidate
                                        position="relative"
                                        stroke="#424242"
                                        top="unset"
                                        right="-3px"
                                        shouldShow={true} isValid={false} /></span>
                                }))
                            } else {
                                if (!loading && !success) {
                                    setState(prev => ({
                                        ...prev,
                                        loading: true,
                                        shouldShow: false,
                                        serverResponse: ""
                                    }))
                                    res = await API.disputeLead(Object.fromEntries(Object.keys(fields).map(
                                        fieldKey => {
                                            let field = fields[fieldKey]
                                            return ([field.name, field.value])
                                        }
                                    )))
                                }
                                setState(prev => ({
                                    ...prev,
                                    loading: false,
                                    success: res['success'],
                                    serverResponse: res['msg']
                                }))
                            }


                        } catch (error) {
                            console.log("error::")
                            console.log(error)
                        }
                        console.log("res:", res)
                    }}
                    loading={loading}
                    width="100%"
                    padding="1em 5em"
                    label="Submit" margin="20px auto 0" />
                <p>{serverResponse}</p>
            </Flexbox>
        </PageContentContainer>
    )
}

export default Dispute