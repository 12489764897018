import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Flexbox from '../Flexbox'
import Button from '../ButtonHome'

const EmailUsLink = styled.a`
all: unset;
cursor: pointer;
width: min-content;
white-space: nowrap;
font-weight: 700;
`;

const Success = () => {
    const navigate = useNavigate()
    return (
        <Flexbox alignText="center" column color="var(--formColor)" height="var(--defaultContainerHeight)" alignItems="center" justifyContent="center" background="var(--formBackground)" margin="calc(var(--navBarHeight) * -1) 0 0">
            <h1>Success!</h1>
            <p style={{maxWidth: '600px'}}>We will contact you shortly with pricing for your selected locations.  Please contact us at <EmailUsLink href="mailto:help@lawyerpayperlead.com">help@lawyerpayperlead.com</EmailUsLink> if you have any questions</p>
            <Button bgdHover="#fff" width="300px" text="Learn More" boxShadow="0 0 7px rgba(0,0,0,0.5)" onClick={() =>navigate('/faq')} border="2px solid var(--primary)"/>
        </Flexbox>
    )
}

export default Success