import styled from 'styled-components'

export const List = styled.ul`
display: flex;
padding: 0;
max-width: ${({ maxWidth = '400px' }) => maxWidth};
width: ${({ listWidth = '90%' }) => listWidth};
flex-wrap: wrap;
flex-direction: ${({flexDirection = 'column'}) => flexDirection};
gap: 10px;
margin-bottom: 0;

`;

export const Text = styled.p`
color: var(--secondary);

`;

export const Check = styled.div`
height: 15px;
width: 15px;
box-shadow: var(--defaultInputBoxShadow);
background: ${({ checked, checkedBgd = '#fff', uncheckedBgd = "var(--underLineThemeBgd)" }) => checked ? checkedBgd : uncheckedBgd};
border: ${({ checked, checkedBorder = '3px solid #fff', uncheckedBorder = '3px solid rgba(255,255,255,0.25)' }) => checked ? checkedBorder : uncheckedBorder};
display: flex;
  position: relative;
  border-radius: 100%;
  height: 25px;
  width: 25px;
	/* z-index: 5; */
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
  flex-shrink: 0;
:before {
    display: block;
  /* position: absolute; */
	content: '';
  border-radius: 100%;
  height: 11px;
  width: 11px;
  /* top: 5px;
	left: 5px; */
  margin: auto;
  background: ${({ checked }) => checked ? 'var(--formBlue1)' : 'transparent'};
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
  flex-shrink: 0;
}
`;

export const ListItem = styled.li`
    /* height: 40px; */
    list-style: none;
    text-align: left;
    display: flex;
    align-items: center;
        color: ${({color = "var(--secondary)"}) => color} ;
    p{
        font-size: ${({fontSize = '0.8rem'}) => fontSize};
        margin: 0;
        color: ${({color = "var(--secondary)"}) => color} ;
    }
    label{
        color: ${({labelColor}) => labelColor};
        font-size: ${({fontSize = '0.8rem'}) => fontSize};
    }
    h3{
        margin: 0;
    }
    gap: 5px;

    /* input[type=radio]:checked ~ .check {
        border: 5px solid #0DFF92; */
/* } */
    
`;