import React, { useState, useEffect } from 'react'
import Flexbox from '../Flexbox'
import { useLocation, useParams } from 'react-router-dom'
import { validateEmail } from '../Form'

const Budget = () => {
    const [ state, setState ] = useState({
        id: '',
        email: '',
    })
    const { id } = useParams()
    return (
        <div>Budget</div>
    )
}

export default Budget