import React, { useState } from 'react'
import styled from 'styled-components'
import Flexbox from '../Flexbox'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import PageContentContainer from '../PageContentContainer'
import Icon from '../Icon'
import Footer from '../Footer'

import leadsIcon from '../../images/leads@2x.png'
import arrowIcon from '../../images/arrowIcon@2x.png'
import { IconWrapper } from '../Icon/Icon.styles'

import { Wrapper } from '../GridContainer/GridContainer.styles'

const P = styled.p`
text-align: ${({ textAlign = 'center' }) => textAlign};
max-width: 600px;
font-size: 1.2rem;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {

        text-align: center !important;
    }
`;

const Icon2 = styled(IconWrapper)`
animation: float 3s alternate infinite;
@keyframes float{
    from{transform: translateX(0);}
    to{transform: translateY(20px);}
}
`;

const H3 = styled.h3`
margin-bottom: 0;
`;

const GridContainer2 = styled(Wrapper)`
    @media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
        grid-template-columns: 1fr;
        text-align: center;

    }

`;

const WhyOurLeadsCostMore = () => {
    return (
        <PageContentContainer column scrollSnapType="y mandatory" className=" centerTextMobile" >
            <Flexbox scrollSnapAlign="center" width="100%" column minHeight="var(--defaultContainerHeight)" padding="0 0 50px">
                <h1>Why Our Leads Cost More</h1>
                <Flexbox flexGrow="1" column justifyContent="center" display="flex">
                    <Icon src={leadsIcon} />
                    <h3 style={{ marginBottom: '0' }}>Our leads cost more because they’re worth more.</h3>
                    <P>Search engine advertising fees are based on a “pay per click” model.
                        <br /><br />Clicks relating to attorneys are THE MOST EXPENSIVE.
                    </P>
                    <h3 style={{ marginBottom: '0' }}>Attorney clicks cost more than any other category on Earth.</h3>
                </Flexbox>
                <h2 style={{ textAlign: 'center' }}>If you’re not familiar with how costly search engine advertising can be,<br />here is an example.</h2>
                <Icon2 src={arrowIcon} />
            </Flexbox>

            <GridContainer2 scrollSnapAlign="center" gridTemp={{ rows: 'minmax(var(--defaultContainerHeight), min-content)', columns: '1fr 1fr' }} width="100%">
                <GridItem background="var(--formBackground)" width="100%" height="100%" className="hideOnMobile">

                </GridItem>
                <GridItem padding="30px" justify="center" display="flex" flexDirection="column">

                    <H3 className="gradientText">National Average</H3>
                    <P textAlign="left">
                        Based upon a national average, criminal defense clicks cost approximately $125.<br /><br />
                    </P>
                    <H3 className="gradientText">Contact Rate</H3>
                    <P textAlign="left">
                        Of these clicks, only 5% will actually contact you and become a “lead.”  This means one lead costs around $2,500.<br /><br />Now how many will actually pay to retain your services, this may only be 10-15%.  Meaning, it can cost $25,000 to land a client.<br /><br />You can see why search engines are dominated by large, established firms with massive marketing budgets.
                    </P>
                </GridItem>
            </GridContainer2>
            <GridContainer2 className="reverse" scrollSnapAlign="center" gridTemp={{ rows: 'minmax(var(--defaultContainerHeight), min-content)', columns: '1fr 1fr' }} width="100%">
                <GridItem padding="30px" justify="center" display="flex" flexDirection="column">

                    <H3 className="gradientText">Personal Injury Premiums</H3>
                    <P textAlign="left">
                        Personal injury is even worse, with <strong className="gradientText">click costs of $250-300</strong>.<br /><br />Because personal injury cases don’t typically require up front fees, a higher percentage of clients are likely to hire, but the cost of generating a lead can easily reach $5k or even $10k.
                    </P>
                    <H3 className="gradientText">Contact Rate</H3>
                    <P textAlign="left">
                        78 of the most expensive 100 keywords on Google are attorney-related.<br /><br />If others are sending you a lead for less than the cost of 1 click, it’s worth questioning how many of these leads REALLY came from highly motivated search engine users.
                    </P>
                </GridItem>
                <GridItem background="var(--formBackground)" width="100%" height="100%" className="hideOnMobile">

                </GridItem>
            </GridContainer2>
            <Flexbox background="var(--formBackground)" color="var(--formColor)" scrollSnapAlign="center" width="100%" justifyContent="center" column minHeight="var(--defaultContainerHeight)" >
                <h1>So Why Do Lawyers Pay?</h1>
                <h2 style={{ marginBottom: '0' }}>On average, returns are 8x.</h2>
                <P>
                    <br />Every $10k spent can become $80k in revenue…and the returns can often be much larger than this.
                </P>
            </Flexbox>
            <Flexbox scrollSnapAlign="center" column >
            </Flexbox>
        </PageContentContainer>
    )
}

export default WhyOurLeadsCostMore