import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
// phone number optional, email before number
import Step from './Step'
import NavArrow from './NavArrow'
import Flexbox from '../Flexbox'
import Button from '../Button'
import FormContentContainer from './FormContentContainer'
import ProgressBar from './ProgressBar'
import FormGrid from './FormGrid'
import PageContentContainer from '../PageContentContainer'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import { GridWrapper } from './Form.styles'

import phoneIconForm from '../../images/phoneIconForm.svg'
import emailIcon from '../../images/emailIcon.svg'
import dwiOnly from '../../images/dwiOnly2.svg'
import { formatPhoneNumber, gtag_report_conversion } from '../../utils'
import dwiAndOtherCriminal from '../../images/dwiAndOtherCriminal.svg'
import massTort from '../../images/massTort.svg'
import personalInjury from '../../images/personalInjury.svg'
import workersComp from '../../images/workersComp.svg'
import realEstate from '../../images/realEstate.svg'
import bankruptcy from '../../images/bankruptcy.svg'
import autoAccident from '../../images/autoAccident.svg'
import immigrationLaw from '../../images/immigrationLaw.svg'
import familyLaw from '../../images/familyLaw.svg'
import businessLaw from '../../images/businessLaw.svg'
import lemonLaw from '../../images/lemonLaw.svg'
import other from '../../images/other.svg'
import medMal from '../../images/medMal.svg'
import socialSecurity from '../../images/socialSecurity.svg'
import debtCollection from '../../images/debtCollection.svg'
import estatePlanning from '../../images/estatePlanning.svg'
import appellate from '../../images/appellate.svg'

import API from '../../API'

const MailToLink = styled.a`
all: unset;
cursor: pointer;
font-weight: var(--bold);
width: min-content;
`;

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validateName(str) {
    return /\d/.test(str);
}

const Form = () => {
    const navigate = useNavigate()
    let previousFormData = window.sessionStorage.getItem('formData')
    if (previousFormData) {
        previousFormData = JSON.parse(previousFormData)
    }
    const getPrevValue = (name, defaultVal = '') => {
        return previousFormData && previousFormData[name] ?
            name === 'active' && previousFormData[name] === 'submit' ? defaultVal :
                previousFormData[name]
            : defaultVal
    }
    const nextStep = async () => {
        console.log("disabled", disabled)
        const activeIndex = stepOrder.findIndex(stepId => stepId === active)
        const nextStep = activeIndex + 1
        if (steps[active].onNextStep) {
            let res = steps[active].onNextStep()
            if (res === false)
                return
        }
        if (disabled)
            return
        if (isLastStep()) {
            setState(prev => ({ ...prev, active: stepOrder[nextStep] }))
            await submitForm()

        }
        else if (nextStep < formLength)
            setState(prev => ({ ...prev, active: stepOrder[nextStep] }))

    }
    const _data = {
        steps: {
            'step-1': {
                id: 'step-1',
                name: 'geographicArea',
                type: 'geographicArea',
                placeholder: '',
                label: "",
                value: getPrevValue('geographicArea', ['Nationwide']),
                stepWidth: '100%',
                alignContent: 'start',
                isSelected: function (_val) {
                    let regex = new RegExp(_val, "i")
                    return this.value.find(it => regex.test(it)) !== undefined

                },
            },
            'step-8': {
                id: 'step-8',
                name: 'geographicAreaDetailed',
                type: 'geographicAreaDetailed',
                placeholder: '',
                label: "",
                value: getPrevValue('geographicAreaDetailed', []),
                stepWidth: 'min-content',
                dependency: 'geographicArea',
                onDependencyUpdate: function (_val = [], _stepOrder, _steps) {
                    let arrIncludesDC = _val.find(it => {
                        return (new RegExp('District of Columbia', "i").test(it))
                    })
                    let newStepOrder = _stepOrder
                    if (_val.length === 0)
                        return { newStepOrder }
                    if (_val.find(v => v === 'Nationwide') || _val.length >= 3 || (_val.length === 1 && arrIncludesDC)
                    ) {
                        newStepOrder = newStepOrder.filter(step => step !== this.id)
                        let dcObject = {
                            type: 'place', //place
                            innerText: 'Washington, District Of Columbia',
                            value: 'Washington, District Of Columbia',
                            pla_code: '1150000',
                            name: 'Washington',
                            state: 'District Of Columbia',
                            ste_name: 'District Of Columbia',
                            ste_code: '11',
                            id: 'Washington, District Of Columbia',
                            key: '1150000',
                        }
                        return { newStepOrder, dcObject }
                    } else {
                        if (newStepOrder.includes(this.id)) {
                            if (!arrIncludesDC)
                                return { newStepOrder }
                            else {
                                let dcObject = {
                                    type: 'place', //place
                                    innerText: 'Washington, District Of Columbia',
                                    value: 'Washington, District Of Columbia',
                                    pla_code: '1150000',
                                    name: 'Washington',
                                    state: 'District Of Columbia',
                                    ste_name: 'District Of Columbia',
                                    ste_code: '11',
                                    id: 'Washington, District Of Columbia',
                                    key: '1150000',
                                }
                                return { newStepOrder, dcObject }
                            }
                        }
                        const indexOfDependency = _stepOrder.findIndex(step => _steps[step]['name'] === this.dependency)
                        if (indexOfDependency >= 0) {
                            newStepOrder.splice(indexOfDependency + 1, 0, this.id)
                        }

                        return { newStepOrder }
                    }
                },
                onChange: function (_val) {
                    console.log("_val is:", _val)
                    if (Array.isArray(_val))
                        setState(prev => ({
                            ...prev,
                            steps: {
                                ...prev.steps,
                                [this.id]: {
                                    ...prev.steps[this.id],
                                    value: _val
                                }
                            }
                        }))
                    else if (this.isSelected(_val))
                        setState(prev => ({
                            ...prev,
                            steps: {
                                ...prev.steps,
                                [this.id]: {
                                    ...prev.steps[this.id],
                                    value: [...prev.steps[this.id].value.filter(it => it.id !== _val.id)]
                                }
                            }
                        }))
                    else
                        setState(prev => ({
                            ...prev,
                            steps: {
                                ...prev.steps,
                                [this.id]: {
                                    ...prev.steps[this.id],
                                    value: [...prev.steps[this.id].value, _val]
                                }
                            }
                        }))
                },
                isSelected: function (_val) {
                    let _key = _val['type'] === 'place' ? 'pla_code' : 'coty_code'
                    console.log("_val", _val, _val[_key], _key)
                    let regex = new RegExp(_val[_key], "i")
                    return (this.value.find(it => {

                        return (it['type'] === _val['type'] && regex.test(it[_key]))
                    }) !== undefined)
                    // return this.value.find(it => it.id === _val.id)
                },
            },
            'step-2': {
                id: 'step-2',
                name: 'name',
                type: 'input',
                // labelInternal: true,
                // subtextInternal: true,
                placeholder: 'Enter your first and last name',
                label: "What's your name?",
                value: getPrevValue('name', ''),
                validate: function () {
                    return !/\d/.test(this.value) && this.value !== ''
                }
            },
            'step-3': {
                id: 'step-3',
                name: 'firmName',
                type: 'input',
                placeholder: 'Enter your firm name',
                label: "What's your firm name?",
                value: getPrevValue('firmName', ''),
                validate: function () {
                    return this.value !== ''
                }
            },
            'step-4': {
                id: 'step-4',
                name: 'phoneNumber',
                type: 'input',
                placeholder: { phoneNumber: '123-456-7890', extension: '25' },
                label: "Hey, can i get your number? (Optional)",
                type: "tel",
                value: getPrevValue('phoneNumber', { phoneNumber: '', extension: '' }),
                validate: function () {
                    if (this.value['phoneNumber'] === '' && this.value['extension'] === '')
                        return true
                    if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(this.value['phoneNumber']))
                        return false
                    if (this.value['extension'] === '')
                        return true
                    if (/^[0-9]+$/.test(this.value['extension']))
                        return true
                    return false

                },
                onChange: function (event, type = 'extension') {
                    const _val = event.target.value.replace(/\D/g, '')
                    console.log("value is", _val, 'extension')
                    if (type === 'extension')
                        setState(prev => ({
                            ...prev,
                            steps: {
                                ...prev.steps,
                                [this.id]: {
                                    ...prev.steps[this.id],
                                    value: { ...prev.steps[this.id].value, extension: _val }
                                }
                            }
                        }))
                },
            },
            'step-33': {
                id: 'step-33',
                name: 'finalStep',
                type: 'final-step',
                stepWidth: '100%',
                stepHeight: '100%',
                gridTempAreas: "'back content next'",
                containerProps: {
                    mobilePadding: '0 20px 0',
                    border: 'none',
                    height: '100%',
                    padding: '0 20px'
                },
                fieldOrder: ['field-3', 'field-1', 'field-2'],
                validate: function () {
                    return this.fieldOrder.reduce((a, b) => {
                        let field = this.fields[b]
                        if (typeof (field.validate) === 'function') {
                            let fieldVal = field.validate()
                            if (!fieldVal)
                                a = false

                        }
                        return a
                    }, true)
                },
                fields: {
                    'field-1':
                    {

                        id: 'field-1',
                        name: 'phoneNumber',
                        placeholder: { phoneNumber: '123-456-7890', extension: '25' },
                        label: "Phone (Optional)",
                        stepWidth: '100%',
                        labelInternal: true,
                        type: "tel",
                        isValid: true,
                        shouldShow: false,
                        value: getPrevValue('phoneNumber', { phoneNumber: '', extension: '' }),
                        validate: function () {
                            if (this.value['phoneNumber'] === '' && this.value['extension'] === '')
                                return true
                            if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(this.value['phoneNumber']))
                                return false
                            if (this.value['extension'] === '')
                                return true
                            if (/^[0-9]+$/.test(this.value['extension']))
                                return true
                            return false

                        },
                        onKeyDown: function (event) {
                            console.log("Event", event)
                        },
                        onChange: function (event, type = 'extension') {
                            let _val = event.target.value.replace(/\D/g, '')
                            setState(prev => ({
                                ...prev,
                                steps: {
                                    ...prev.steps,
                                    ['step-33']: {
                                        ...prev.steps['step-33'],
                                        fields: {
                                            ...prev.steps['step-33'].fields,
                                            [this.id]: {
                                                ...prev.steps['step-33'].fields[this.id],
                                                value: { ...prev.steps['step-33'].fields[this.id].value, [type]: _val }
                                            }
                                        }
                                    }
                                }
                            }))
                        },
                    },
                    'field-2': {
                        id: 'field-2',
                        name: 'additionalDetails',
                        type: 'textArea',
                        placeholder: 'Additional Details',
                        label: "Additional details (Optional)",
                        value: getPrevValue('additionalDetails'),
                        isValid: true,
                        shouldShow: false,
                        maxlength: 500,
                        rows: 5,
                        onChange: function (event) {
                            const _val = event.target.value
                            setState(prev => ({
                                ...prev,
                                steps: {
                                    ...prev.steps,
                                    ['step-33']: {
                                        ...prev.steps['step-33'],
                                        fields: {
                                            ...prev.steps['step-33'].fields,
                                            [this.id]: {
                                                ...prev.steps['step-33'].fields[this.id],
                                                value: _val
                                            }
                                        }
                                    }
                                }
                            }))

                        }
                    },
                    'field-3': {
                        id: 'field-3',
                        name: 'email',
                        type: 'email',
                        placeholder: 'Enter your email',
                        label: "Email",
                        value: getPrevValue('email', ''),
                        isValid: false,
                        shouldShow: false,
                        validate: function () {
                            return validateEmail(this.value)
                        },
                        onChange: function (event) {
                            const _val = event.target.value
                            let isValid = this.validate(_val)
                            console.log("is valid::::", isValid)
                            setState(prev => ({
                                ...prev,
                                steps: {
                                    ...prev.steps,
                                    ['step-33']: {
                                        ...prev.steps['step-33'],
                                        fields: {
                                            ...prev.steps['step-33'].fields,
                                            [this.id]: {
                                                ...prev.steps['step-33'].fields[this.id],
                                                value: _val,
                                                isValid
                                            }
                                        }
                                    }
                                }
                            }))

                        }
                    },
                },



            },
            'step-5': {
                id: 'step-5',
                name: 'email',
                placeholder: 'Enter your email',
                label: "What's your email?",
                value: getPrevValue('email', ''),
                isValid: true,
                shouldShow: false,
                validate: function () {
                    return validateEmail(this.value)
                },
                subtext:
                    <Flexbox column width="calc(100% - 8px)" margin="20px 0 0">
                        <Button
                            background="rgba(224, 245, 255,0.25)"
                            // color="#376afe"
                            color="#fff"
                            padding="20px 0"
                            colorHover="#376afe"
                            border="2px solid #fff"
                            borderHover="2px solid #fff"
                            text="Submit"
                            letterSpacing="0.05em"
                            bgdHover="#fff"
                            onClick={nextStep} width="100%" />
                    </Flexbox>,
            },
            'step-9': {
                id: 'step-9',
                name: 'practiceArea',
                type: 'iconSelection',
                isOptional: false,
                additionalKeys: ['practiceAreaType'],
                validate: function () {
                    return this.value.length > 0
                },
                itemsPerRow: 6,
                itemsPerRowMobile: 2,
                itemMargin: '7.5px',
                multiSelect: true,
                storeFullOption: true,
                enum: {
                    "DUI/DWI": {
                        text: "DUI/DWI",
                        id: 'DUI/DWI',
                        practiceAreaType: 'Criminal Defense',
                        img: dwiOnly,
                    },
                    "Criminal Defense": {
                        text: "Criminal Defense",
                        id: 'Criminal Defense',
                        practiceAreaType: 'Criminal Defense',
                        img: dwiAndOtherCriminal,
                    },
                    "Mass Tort": {
                        text: "Mass Tort",
                        id: 'Mass Tort',
                        practiceAreaType: 'Personal Injury',
                        img: massTort,
                    },
                    "Personal Injury": {
                        text: "Personal Injury",
                        id: 'Personal Injury',
                        practiceAreaType: 'Personal Injury',
                        img: personalInjury,
                    },
                    "Workers' Comp": {
                        text: "Workers' Comp",
                        id: "Workers' Comp",
                        practiceAreaType: 'Personal Injury',
                        img: workersComp,
                    },
                    "Bankruptcy": {
                        text: "Bankruptcy",
                        id: "Bankruptcy",
                        practiceAreaType: 'Bankruptcy',
                        img: bankruptcy,
                    },
                    "Real Estate": {
                        text: "Real Estate",
                        id: "Real Estate",
                        practiceAreaType: 'Real Estate',
                        img: realEstate,
                    },
                    "Auto Accident": {
                        text: "Auto Accident",
                        id: 'Auto Accident',
                        practiceAreaType: 'Personal Injury',
                        img: autoAccident,
                    },
                    "Immigration": {
                        text: "Immigration",
                        id: 'Immigration',
                        practiceAreaType: 'Immigration',
                        img: immigrationLaw,
                    },
                    "Family Law": {
                        text: "Family Law",
                        id: 'Family Law',
                        practiceAreaType: 'Family Law',
                        img: familyLaw,
                    },
                    "Business": {
                        text: "Business",
                        id: 'Business',
                        practiceAreaType: 'Business',
                        img: businessLaw,
                    },
                    "Lemon Law": {
                        text: "Lemon Law",
                        id: 'Lemon Law',
                        practiceAreaType: 'Lemon Law',
                        img: lemonLaw,
                    },
                    "Med Mal": {
                        text: "Med Mal",
                        id: 'Med Mal',
                        practiceAreaType: 'Med Mal',
                        img: medMal,
                    },
                    "Social Security": {
                        text: "Social Security",
                        id: 'Social Security',
                        practiceAreaType: 'Social Security',
                        img: socialSecurity,
                    },
                    "Debt Collection": {
                        text: "Debt Collection",
                        id: 'Debt Collection',
                        practiceAreaType: 'Debt Collection',
                        img: debtCollection,
                    },
                    "Estate Planning": {
                        text: "Estate Planning",
                        id: 'Estate Planning',
                        practiceAreaType: 'Estate Planning',
                        img: estatePlanning,
                    },
                    "Appellate": {
                        text: "Appellate",
                        id: 'Appellate',
                        practiceAreaType: 'Appellate',
                        img: appellate,
                    },
                    "other-1": {
                        text: "Other",
                        id: 'other-1',
                        practiceAreaType: 'Other',
                        img: other,
                    },
                    // "additional-practice-areas": {
                    //     type: 'textAreaInput',
                    //     id: 'additional-practice-areas',
                    //     practiceAreaType: 'Criminal',
                    //     value: getPrevValue('additionalPracticeAreas', ''),
                    //     text: "Additional Practice Areas",
                    //     placeholder: 'If needed, please provide more info about which practice areas you are interested in.',
                    //     tileProps: { flexBasis: '100%' },
                    //     onChange: function (_val) { this.value = _val }
                    // },
                },
                tileProps: {
                    flexDirection: 'row',
                    maxHeight: '90px',
                    mobileFontSize: 'calc(1/0.9 * 16px)',
                    // height: 'min-content',
                    minWidth: '140px',
                    fontSize: 'calc(1/0.9*15px)',
                    flexDirectionMobile: 'column'
                    // width: '100%',
                    // maxWidth: '700px'
                },
                imgProps: {
                    height: 'auto',
                    width: '35px',
                    maxHeight: '50px'
                },
                // containerProps: {
                //     flexDirection: 'column',
                //     justifyContent: 'start',
                //     flexWrap: 'nowrap'
                // },
                // isFlex: false,
                value: getPrevValue('practiceArea', []),
                textAreaValue: getPrevValue('additionalPracticeAreas', ''),
                showTextOnly: false,
                label: 'Practice Area(s)',
                labelMargin: '0',
                subtext: `Don't see your practice area below? Select "Other"`,
                subtextStyles: { fontSize: '14px' },
                labelInternal: true,
            },
            'step-7b': {
                id: 'step-7b',
                name: 'phoneCallRequested',
                type: 'iconSelection',
                multiSelect: false,
                stepWidth: 'min-content',
                enum: {
                    "phone-call": {
                        text: "Phone Call",
                        value: 'phone-call',
                        id: 'phone-call',
                        img: phoneIconForm,
                        label: <Flexbox ><span style={{ textAlign: 'center' }}>Thanks for your interest. We'll be in touch via <span style={{ width: '3em', display: 'inline-block' }}><strong style={{ textDecoration: 'underline' }}>phone</strong></span> to provide more information.</span></Flexbox>
                    },
                    "email": {
                        text: "Email",
                        value: 'email',
                        id: 'email',
                        img: emailIcon,
                        label: <Flexbox ><span style={{ textAlign: 'center' }}>Thanks for your interest. We'll be in touch via <span style={{ width: '3em', display: 'inline-block' }}><strong style={{ textDecoration: 'underline' }}>email</strong></span> to provide more information.</span></Flexbox>
                    },
                },
                value: getPrevValue("phoneCallRequested", "phone-call"),
                onChange: function (_val) {
                    setState(prev => ({
                        ...prev,
                        steps: {
                            ...prev.steps,
                            ['step-7b']: {
                                ...prev.steps['step-7b'],
                                value: _val
                            }
                        }
                    }))
                },
                get label() { return this.enum[this.value].label },
                labelInternal: true,
                dependency: 'phoneNumber',
                onDependencyUpdate: function (_phoneNumber = '') {
                    if (_phoneNumber.length > 0)
                        return 'step-7b'
                    else return 'step-7a'
                }
            },
            'step-7a': {
                id: 'step-7a',
                name: 'phoneCallRequested',
                type: 'text',
                stepWidth: 'min-content',
                value: getPrevValue("phoneCallRequested", "email"),
                heading: "Thanks for your interest. We'll contact you with more information.",
                textAlign: 'center',
                labelInternal: true,
                dependency: 'phoneNumber',
                onDependencyUpdate: function (_phoneNumber = '') {
                    if (_phoneNumber.length > 0)
                        return 'step-7b'
                    else return 'step-7a'
                }
            },
            'step-intro': {
                id: 'step-intro',
                name: 'stepIntro',
                type: 'text',
                stepWidth: '70%',
                // value: getPrevValue("phoneCallRequested", "email"),
                heading: "This form typically takes about 30 seconds to complete and will ask what practice areas and geographic regions you're interested in, so we can provide exact pricing.",
                subtext: <>If you'd rather email us or setup a call, contact us at <MailToLink href="mailto:help@lawyerpayperlead.com">help@lawyerpayperlead.com</MailToLink></>,
                textAlign: 'center',
                labelInternal: true,
            },
            'submit': {
                id: 'submit',
                name: 'submit',
                // placeholder: 'Enter your email',
                // label: "What's your email?",
                // value: '',
                // validate: function () {
                //     return validateEmail(this.value)
                // }
            },


        },
        stepOrder: getPrevValue('stepOrder', [
            'step-intro',
            'step-1',
            'step-9',
            'step-2',
            'step-3',
            'step-33',
            // 'step-4',
            // 'step-7a',
            // 'step-5',
            'submit'
        ]),
        get active() {
            return getPrevValue("active", this.stepOrder[0])
        },
        startTime: Date.now(),
        endTime: null,
    }
    const [state, setState] = useState(_data)
    const [disabled, setDisabled] = useState(false)
    const [isKeyReleased, setIsKeyReleased] = useState(false)
    const initialRender = useRef(true)

    const handleChange = (name, _value = null) => (event) => {
        const stepId = getStep(name)
        if (name === 'geographicArea' && _value) {
            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [stepId]: {
                        ...prev.steps[stepId],
                        value: [...prev.steps[stepId].value, _value]
                    }
                }
            }))
        }
        else if (name !== 'phoneNumber' && event.target) {
            let value = event.target.value
            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [stepId]: {
                        ...prev.steps[stepId],
                        value
                    }
                }
            }))
        }
    }

    const { steps, active, stepOrder, } = state
    const formLength = Object.keys(stepOrder).length


    const formatData = (flatten = true) => {
        const data = {}
        data.startTime = state['startTime']
        data.endTime = Date.now()
        stepOrder.forEach(stepId => {
            if (stepId === "submit" || stepId === 'step-intro')
                return

            const step = steps[stepId]
            if (!flatten) {
                data[step.name] = step.value
                if (step.name === "practiceArea" && step.textAreaValue !== '') {
                    data['additionalPracticeAreas'] = step.textAreaValue
                }
                if (step.name === 'finalStep') {
                    Object.keys(step['fields']).forEach(fieldKey => {
                        const field = step.fields[fieldKey]
                        data[field.name] = field.value
                    })
                }
                return
            }

            if (step.name === "geographicAreaDetailed") {
                data[step.name] = step.value.map(it => ({
                    // id: it.id, county: it.county, state: it.state
                    ...it
                }))
                return
            }

            if (step.name === "practiceArea" && step.textAreaValue !== '') {
                data['additionalPracticeAreas'] = step.textAreaValue
            }
            console.log("step", step)
            if (step.name === 'finalStep') {
                Object.keys(step['fields']).forEach(fieldKey => {
                    const field = step.fields[fieldKey]
                    data[field.name] = field.value
                })
            }
            if (Array.isArray(step.value)) {
                // data[step.name] = step.value.map(it => it['id'] ? it['id'].includes('not-sure') ? { id: 'not-sure', displayName: 'Not Sure' } : it['id'].includes('other') ? { id: 'other', displayName: 'Other' } : it : it.includes('not-sure') ? 'not-sure' : it)
                data[step.name] = step.value.map(it => handleStepValues(it, step))
            } else if (typeof (step.value) === 'string')
                data[step.name] = handleStepValues(step.value, step)
            else
                data[step.name] = step.value

        })
        return data
    }
    const handleStepValues = (it, step) => {
        if (it['id']) {
            if (/-\d/.test(it['id'])) {
                console.log("step enum is: ", step.enum, it)
                let displayName = step.enum[it.id]['text'] || step.enum[it.id]['displayName']
                return ({ ...it, id: it['id'].split(/-\d/)[0], displayName })
            } else {
                return it
            }

        } else {
            return it.split(/-\d/)[0]
        }
    }


    const submitForm = async () => {
        try {
            const data = formatData()
            console.log("submitting", data)
            let res = await API.submitForm(data)
            if (window.sessionStorage.getItem('formData'))
                sessionStorage.removeItem('formData')
            if (res) {
                if (window.dataLayer && Array.isArray(window.dataLayer)) {
                    let ev = {
                        'event': 'form_submission',
                        'form_id': 'PRIMARY FORM',
                        // 'form_entry': formEntry.current
                    }
                    ev['isCAC'] = true
                    window.dataLayer.push(ev)
                }
            }
            // gtag_report_conversion(undefined, 'AW-11062156677/pGOJCI3Ev4cYEIW77Jop')
            navigate('/success')

        } catch (error) {
            console.log("an error occurred", error)

            const { response: { status, data: { msg } } } = error
            console.log("msg, status", msg, status)
            navigate('/error', { state: { error: { status, msg }, ref: 'form-submission' } })
        }

    }

    function hasPhoneNumber() {

        const phoneNumberStep = getStep('phoneNumber')
        if (!phoneNumberStep)
            return false
        if (steps[phoneNumberStep].value === '')
            return false
        return true
    }

    const getStep = (fieldName) => {
        const stepId = Object.keys(steps).find(id => {
            return steps[id].name === fieldName
        })
        return stepId
    }

    const getSelectedStates = () => {
        const stepId = getStep('geographicArea')
        if (stepId) {
            let _val = steps[stepId].value
            return steps[stepId].value
        }

    }

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false
            return
        }
        validateActiveStep()
        sessionStorage.setItem('formData', JSON.stringify({ ...formatData(false), active, stepOrder }))
    }, [state])

    const validateActiveStep = () => {
        if (steps[active]) {
            const valid = steps[active].validate ? steps[active].validate(steps[active].value) : true
            if (valid) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        }
    }

    const geographicAreaOnChange = (_val) => {
        const dependentStep = Object.keys(steps).find(step => steps[step]['dependency'] === 'geographicArea')
        const geoStepId = getStep('geographicArea')
        const geoStepDetailedId = getStep('geographicAreaDetailed')
        let newStepOrder = stepOrder
        let dcObject;
        console.log("_val", _val)
        console.log("regex", _val.find(it => (new RegExp('District of Columbia', "i").test(it))))
        console.log("steps[geoStepDetailedId]['value'].find(it => it['pla_code'] === '1150000')", steps[geoStepDetailedId]['value'].find(it => it['pla_code'] === '1150000'))
        if (dependentStep) {
            let depStepRes = steps[dependentStep].onDependencyUpdate(_val, stepOrder, steps)
            newStepOrder = depStepRes['newStepOrder']
            dcObject = depStepRes['dcObject']

            //dcObject
        }
        if (dcObject && !steps[geoStepDetailedId]['value'].find(it => it['pla_code'] === '1150000')) {
            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [geoStepId]: {
                        ...prev.steps[geoStepId],
                        value: _val
                    },
                    [geoStepDetailedId]: {
                        ...prev.steps[geoStepDetailedId],
                        value: [...prev.steps[geoStepDetailedId]['value'], dcObject]
                    },
                },
                stepOrder: newStepOrder
            }))
        }
        else if (steps[geoStepDetailedId]['value'].find(it => it['pla_code'] === '1150000') && !_val.find(it => (new RegExp('District of Columbia', "i").test(it)))) {
            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [geoStepId]: {
                        ...prev.steps[geoStepId],
                        value: _val
                    },
                    [geoStepDetailedId]: {
                        ...prev.steps[geoStepDetailedId],
                        value: [...prev.steps[geoStepDetailedId].value.filter(it => it['pla_code'] !== '1150000')]
                    },
                },
                stepOrder: newStepOrder
            }))
        }
        else
            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [geoStepId]: {
                        ...prev.steps[geoStepId],
                        value: _val
                    },
                },
                stepOrder: newStepOrder
            }))
    }

    const geographicAreaDetailedOnChange = (_val) => {
        const stepId = getStep('geographicAreaDetailed')
        const geoStepId = getStep('geographicArea')
        console.log("steps[stepId].isSelected(_val)", _val, steps[stepId].isSelected(_val))
        if (Array.isArray(_val))
            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [stepId]: {
                        ...prev.steps[stepId],
                        value: _val
                    }
                }
            }))
        else if (steps[stepId].isSelected(_val)) {
            if (!(_val['pla_code'] === '1150000'))
                setState(prev => ({
                    ...prev,
                    steps: {
                        ...prev.steps,
                        [stepId]: {
                            ...prev.steps[stepId],
                            value: [...prev.steps[stepId].value.filter(it => it.id !== _val.id)]
                        }
                    }
                }))
            else {
                setState(prev => ({
                    ...prev,
                    steps: {
                        ...prev.steps,
                        [stepId]: {
                            ...prev.steps[stepId],
                            value: [...prev.steps[stepId].value.filter(it => it.id !== _val.id)]
                        },
                        [geoStepId]: {
                            ...prev.steps[geoStepId],
                            value: [...prev.steps[geoStepId].value.filter(it => it !== _val['ste_name'])]
                        }
                    }
                }))
            }
        }
        else
            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [stepId]: {
                        ...prev.steps[stepId],
                        value: [...prev.steps[stepId].value, _val]
                    }
                }
            }))
    }

    const iconSelectionOnChange = (stepId, option) => {
        const step = steps[stepId]
        if (step) {

            let storeFullOption = step.storeFullOption || false
            let multiSelect = step.multiSelect || false
            let currentValue = step.value
            let newValue, arrIncludesNotSure, newValueIsNotSure;
            let additionalKeys = step.additionalKeys || []
            if (storeFullOption) {
                newValue = (({ id, displayName, ...option }) => ({ id, displayName, ...Object.fromEntries(additionalKeys.map(it => [it, option[it]])) }))(option)
                console.log("new value is: ", newValue)
            } else if (typeof (option) !== 'string') {
                newValue = option['id']
            }
            if (multiSelect) {
                arrIncludesNotSure = currentValue.findIndex(el => el['id'] ? el['id'].includes('not-sure') : el.includes('not-sure')) >= 0
                newValueIsNotSure = newValue['id'] ? newValue['id'].includes('not-sure') : newValue.includes('not-sure')

                if (!arrIncludesNotSure && newValueIsNotSure)
                    newValue = [newValue]
                else {
                    newValue = filterDuplicatesOut([...currentValue, newValue], newValue)
                }
            } else {
                newValue = option
            }

            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [stepId]: {
                        ...prev.steps[stepId],
                        value: newValue

                    }
                }
            }))


        }
    }

    const getActiveIndex = (_steps = stepOrder) => _steps.findIndex(stepId => stepId === active)

    const backStep = () => {
        const _steps = Object.keys(steps)
        const activeIndex = stepOrder.findIndex(stepId => stepId === active)//getActiveIndex(_steps)
        const nextStep = activeIndex - 1
        if (nextStep >= 0)
            setState(prev => ({ ...prev, active: stepOrder[nextStep] }))
    }

    const onKeyDown = (name) => (e) => {
        const { key } = e;
        if (key === 'Enter') {
            nextStep()
        }
        else {
            const stepId = getStep(name)
            let value = key
            if (name === 'phoneNumber') {
                const { fields } = steps[active]
                const matchingField = Object.keys(fields).find(key => {
                    const field = fields[key]
                    return field.name === name
                })
                if (matchingField) {
                    let _val = steps[active].fields[matchingField].value['phoneNumber']

                    if (key === 'Backspace' && isKeyReleased) {
                        const _noDash = (_val).replace(/\D/g, '')
                        _val = _noDash.slice(0, _noDash.length - 1)
                        _val = formatPhoneNumber(_val)


                    } else {
                        _val = _val + key.replace(/\D/g, '')
                        const _noDash = (_val).replace(/\D/g, '')
                        _val = formatPhoneNumber(_noDash)

                    }
                    if (name === 'phoneNumber')
                        setState(prev => ({
                            ...prev,
                            steps: {
                                ...prev.steps,
                                [active]: {
                                    ...prev.steps[active],
                                    fields: {
                                        ...prev.steps[active].fields,
                                        [matchingField]: {
                                            ...prev.steps[active].fields[matchingField],
                                            value: { ...prev.steps[active].fields[matchingField].value, phoneNumber: _val }
                                        }
                                    }
                                }
                            }
                        }))
                }

            }
            // setIsKeyReleased(false);
        }
    }

    const onKeyUp = () => {
        setIsKeyReleased(true);
    }

    const isLastStep = () => getActiveIndex() + 1 === formLength - 1
    const isSubmitStep = () => getActiveIndex() + 1 === formLength
    const filterDuplicatesOut = (arr, val) => {
        console.log("arr:::", arr, val)
        if (typeof (val) === 'string') {
            return arr.reduce((a, b) => {
                if (a.includes(b))
                    return a.filter(it => it !== b)
                else
                    a.push(b)
                return a
            }, [])
        } else {
            return arr.reduce((a, b, index, array) => {
                if (a.findIndex(el => el['id'] === b['id']) >= 0)
                    return a.filter(it => it['id'] !== b['id'])
                else if (!b['id'].includes('not-sure'))
                    a.push(b)
                return a
            }, [])
        }
    }
    console.log(steps['step-8'])
    return (
        <PageContentContainer
            marginTop="0"
            position="absolute"
            top="0"
            color="#fff"
            height="100vh"
            background="var(--formBackground)"
            justifyContent="start" alignItems="start" flexDirection="row"
            // padding={isLastStep() ? "0" : "0 0 50px"}
            padding="0"
            mobilePadding={isLastStep() ? "0" : "0 0 50px"}>
            <ProgressBar active={active} formLength={formLength - 1} getActiveIndex={getActiveIndex} />
            <FormGrid stepType={steps[active].type}>
                {!isSubmitStep() &&
                    <GridItem gridArea="back" alignSelf="center" className="formNavigation">
                        <NavArrow direction="back" disabled={active === stepOrder[0]} onClick={backStep} isLastStep={isLastStep()} />
                    </GridItem>
                }
                {
                    (steps[active] && !steps[active]['labelInternal'] && steps[active].label) &&
                    <GridItem gridArea="label"
                        // gridCol="2" gridRow="1" 
                        alignSelf="end" className="formNavigation">
                        <h3 style={{ margin: '0' }}>
                            {
                                (steps[active] && !steps[active]['labelInternal']) && steps[active].label
                            }
                        </h3>
                    </GridItem>
                }

                <FormContentContainer stepType={steps[active].type}>
                    {
                        steps[active] && <Step {...state}
                            geographicAreaOnChange={geographicAreaOnChange}
                            geographicAreaDetailedOnChange={geographicAreaDetailedOnChange}
                            backStep={backStep} nextStep={nextStep} handleChange={handleChange} step={steps[active]} onKeyDown={onKeyDown} onKeyUp={onKeyUp} selectedStates={getSelectedStates()} iconSelectionOnChange={iconSelectionOnChange} />
                    }
                </FormContentContainer>

                {!isSubmitStep() &&
                    <GridItem gridArea="next" alignSelf="center" justifySelf="end" className="formNavigation">
                        {
                            !isLastStep() &&
                            <NavArrow disabled={disabled} onClick={nextStep} direction={isLastStep() ? 'submit' : 'next'} />
                        }

                    </GridItem>
                }
            </FormGrid>
        </PageContentContainer>
    )
}

export default Form