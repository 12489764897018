import React from 'react'
import Section from '../Section'
import SectionAlternative from '../SectionAlternative'
import Button from '../ButtonHome'
import Flexbox from '../Flexbox'
import GridContainer from '../GridContainer'
import Footer from '../Footer'
import FAQ from '../FAQ'
import ThemisAnimated from '../ThemisAnimated'
import backgroundimg from '../../images/law.jpg'
import columns from '../../images/sebastian-pichler-bAQH53VquTc-unsplash.jpg'
import { useNavigate } from 'react-router-dom'


import stopwatch from '../../images/theme2/stopwatch.svg'
import stopwatchAngled from '../../images/theme2/stopwatchAngled.svg'
import chart from '../../images/theme2/chart.svg'
import diamond from '../../images/theme2/diamond.svg'
import solicitor from '../../images/theme2/solicitor.svg'
import ladyJustice from '../../images/ladyjustice.jpg'
import ladyJustice2 from '../../images/ladyjustice2.jpg'
import themis from '../../images/themis2.png'
import themisOutline from '../../images/themisOutline.svg'

import matchIcon from '../../images/matchIcon.svg'
import magnify from '../../images/magnify.svg'
import phone from '../../images/phone.svg'
import { RLink } from '../PrivacyPolicy'
import { Wrapper } from './Home.styles'
import PageList from '../PageListStyle2'


const Home = () => {
    const navigate = useNavigate()

    const listContent = [
        { heading: 'Clients search for help, reach one of our legal sites, and request a lawyer consultation.', img: magnify },
        { heading: 'Client information is immediately sent to you via email or text.', img: matchIcon },
        { heading: 'Call your potential client and provide a consultation about your services.', img: phone },
    ]
    const content = {
        title: 'How it Works',
        props: {
            // color: '#fff',
            // background: 'var(--formBackgroundAngled)',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center'
        },
        justifyContent: 'center',
        flexWrap: 'wrap',
        listItemMargin: 30,
        flexGrow: 0,
        maxWidth: "1200px",
        subTextStyling: {
            // color: 'var(--grey1)',
            // fontSize: '14px'
        },
        subtext:
            [
                {
                    text:
                        <>
                            <h3 style={{ margin: '0' }}>We advertise on search engines</h3>
                            <p className="marginOnMobile" style={{ margin: '.5em 0 .5em 0' }}>
                                Search ads generate attorney leads for categories like personal injury, auto accident, criminal and mass tort.</p>

                            <h3 style={{ margin: '0' }}>Lead is sent to you in real-time</h3>
                            <p className="marginOnMobile" style={{ margin: '.5em 0 0' }}>Leads are screened to ensure they have a valid injury case, or the ability to pay attorney fees.</p>
                            <p className="marginOnMobile" style={{ margin: '0 0 0.5em' }}>Leads are exclusive, delivered in real-time, and guaranteed to be valid.</p>

                            <p className="marginOnMobile" style={{ margin: '0 0 0.5em' }}>Learn more <RLink to="/about">here</RLink>.</p>
                        </>,
                    position: 'start',
                },
                // {
                //     text: <>We use AI-focused search engine marketing to drive traffic to our criminal attorney lead generation sites.</>,
                //     position: 'start'
                // },
                // {
                //     text: <>All leads are actively seeking a private attorney and are screened for ability to pay.</>,
                //     position: 'start'
                // },
                // {
                //     text: 'Leads are delivered in real-time and guaranteed to be valid.',
                //     position: 'start'
                // },
            ],
        listContent,
        button: {
            text: "Get Pricing",
            boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 7px",
            background: "var(--formBackgroundAngled)",
            bgdHover: "#ffffff",
            border: "none",
            borderHover: 'none',
            onClick: () => { navigate('/get-started') }
        }
    }

    const content2 = {
        title: 'Grow Your Practice',
        itemProps: { flexGrowMobile: '0', flexBasisMobile: 'auto' },
        flexWrap: 'wrap',
        justifyContent: 'center',
        listContainerProps: { maxWidth: '1000px', gap: '10px 40px' },
        listContentTextProps: { lineHeight: '1.2em' },
        // maxWidth: 'calc(200px * 2 + 20px * 2 * 2)',
        props: { wrap: 'wrap', gap: '20px' },
        subTextStyling: {
            color: 'var(--grey1)',

        },
        subtext:
            [
                {
                    text: <>Imagine helping more clients get justice, while significantly increasing your revenue. We help achieve this by generating quality leads that are more likely to hire.<br /><br />We can meet any budget requirements.  Start small and scale up as your practice grows.</>,
                    position: 'start'
                },],
        listContent: [
            { background: "var(--defaultButtonBackgroundHover)", heading: 'Real-time Leads', text: 'Receive leads immediately after client fills out a consultation request form. Each lead is sent to you and no other lawyer.', img: stopwatch },
            {
                heading: 'High Quality', text: 'Search ads driven by AI microtargeting mean more conversions and less wasted time. Every lead is screened and qualified.',
                img: diamond
            },
            { heading: 'Scale Up', text: 'Scale up your law practice.  We are able to provide significant and scalable lead volume that grows with your business.   ', img: chart },
            { heading: 'No Hassles', text: 'No sales call required to get started. No upselling or overcharging. No annoying lead management software.', img: solicitor },
        ],
    }
    const content3 = {
        title: 'Get the Reward, Not the Risk',
        props: {
            // background: 'var(--formBackgroundAngled)',
            color: 'var(--formColor)',
            wrap: 'wrap', gap: '20px',
            justifyContent: 'center'
        },
        listParagraphColor: "#fff",
        listItemMargin: 30,
        flexWrap: 'wrap',
        listClassName: 'noWrapOnMobile',
        listContentTextProps: { lineHeight: '1.2em' },
        listContent: [
            { heading: 'Lower cost per client', background: "var(--defaultButtonBackgroundHover)", text: 'We leverage our skills in search engine marketing and AI tools to produce high-quality leads at a price that may be lower than in-house marketing.' },
            { heading: 'Start small with no risk', text: "Start with a tiny order to test us out. No need to commit to 100 or even 10 leads. No contracts or auto-billing. Confirm it's profitable, then scale up. The sky's the limit.", },
            { heading: 'Highly motivated to hire', text: "Search engine leads are the most motivated. We filter out leads who can't afford an attorney or don't have a valid injury case." },
            { heading: 'Substantial profit potential', text: "We sell leads at a great value and they may provide a highly significant ROI. Pay per lead, no commitments or monthly fees." },
        ],
    }


    return (
        <Flexbox width="100%"
            margin="calc(-1 * var(--navBarHeight)) 0 0"
            column
            background="linear-gradient(90deg,var(--formBlue2), var(--formBlue1))"
            // bgdSize="100% 100vh"
            // backgroundAttachment="fixed"
        // overflow="hidden auto" 
        // height="calc(100vh - var(--navBarHeight))"
        >
            <Flexbox width="100%" justifyContent="center"
                // background={`url(${themis})`} 
                // background={`url(${themisOutline})`} 
                height="100vh"
                maxHeight="590px"
                // background="linear-gradient(rgba(56, 106, 255, 0.75), rgba(27, 134, 246, 0.75))"
                position="relative"
                // backgroundIMG={`var(--formBackgroundAngled2), url(${themis})`} 
                backgroundSize="contain"
                // background={`url(${backgroundimg})`} 
                backgroundPosition="center right" >
                <Flexbox position="absolute" justifyContent="end" width="100%" padding="50px">
                    <ThemisAnimated mobileStroke="rgba(255,255,255,0.25)"/>
                </Flexbox>
                <Flexbox column gap="20px" width="100%"
                    zIndex="1"
                    padding="50px "
                    justifyContent="center" alignItems="start">

                    <h1 className="homeHeader" style={{
                        color: "#fff",
                        // maxWidth: "50%",
                        margin: '50px 0 0'
                    }}>Connect with clients.<br />Get hired. Increase revenue.</h1>
                    <GridContainer gap="0.1em" margin="1em 0" mobileGap="1em" >
                        {/* <h3 style={{ margin: '1em 0 !important' }}>{subHeading}
                        </h3> */}
                        <h3 style={{ margin: '0', color: "#fff", fontWeight: '400' }}>Exclusive attorney leads.  No monthly fee. No minimum order.</h3>
                        <h3 style={{ margin: '0', color: "#fff", fontWeight: '400', }}>Scale up your law practice while paying only for performance.</h3>
                    </GridContainer>
                    <Button
                        boxShadow="0 0 7px rgba(0,0,0,0.5)"
                        height="56px" margin="0 0 50px" padding="0" flexShrink="0" width="100%" className="homeHeaderButton" border="none" borderHover="none" colorHover="#fff" text="Get Started" onClick={() => navigate('/get-started')} background="var(--formBackgroundAngled)" bgdHover="var(--formBackgroundAngled2)" />
                </Flexbox>
            </Flexbox>
            <Wrapper>
                <Section {...content} itemsPerRow={3} />
                {/* <Section {...content2} itemsPerRow={2} /> */}
                <PageList />
                <Section {...content3} itemsPerRow={2} />
                {/* <SectionAlternative {...content2alt} /> */}
            </Wrapper>
            <FAQ isHome={true} />
        </Flexbox>
    )
}

export default Home