import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Wrapper as FlexWrapper } from '../Flexbox/Flexbox.styles'

import GridContainer from '../GridContainer'
import Flexbox from '../Flexbox'
import Footer from '../Footer'
import FadeIn from '../FadeIn'
import Icon from '../Icon'
import Button from '../Button'
import AnimatedMap from '../AnimatedMap'
import anime from 'animejs'
import locationIcon from '../../images/location2.svg'
import priceIcon from '../../images/price2.svg'
// import locationIcon from '../../images/locationIcon.svg'
// import priceIcon from '../../images/priceIcon.svg'

const FakeSlider = styled.div`
height: 10px;
width: 300px;
border-radius: 10px;
background: #fff;
display: flex;
align-items: center;
background: var(--formBackgroundAngled);
box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
.ball{
    left: 20%;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    position: relative;
    width: 20px;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    /* border: 1px solid #000; */
}
`;

const MailToLink = styled.a`
all: unset;
cursor: pointer;
font-weight: var(--bold);
color: #fff;
width: min-content;
`;

const Pricing = () => {
    const navigate = useNavigate()
    const tileProps = {
        border: 'var(--defaultBorder)'
    }
    const pricing = [
        { heading: '$50', subtext: 'Minimum price for some rural or low-income areas' },
        { heading: '$75-125', subtext: 'Average range across most of geographic US' },
        { heading: '$150+', subtext: 'CA, FL, TX and other highly competitive markets' },
    ]
    const section2 = [
        { img: locationIcon, text: "Just select which regions you want and we'll provide pricing for each region. Choose specific cities, counties, states, or nationwide." },
        {
            img: priceIcon,
            text: 'All lead prices are agreed upon before you pay.  Then simply select how many leads you want to buy, and fund that amount using credit card or PayPal.  For example, if you want 5 leads that cost $100 each, make a $500 payment.  We will then begin your ad campaign, and you will receive leads in real-time as they come in.  Once your budget is depleted, simply make another payment to continue.'
        },
    ]

    useEffect(() => {
        var myObject = {
            prop1: 5,
            prop2: '0%'
        }
        var numberOfLeadsEl = document.querySelector('.numberOfLeads');
        var tl2 = anime.timeline({
            easing: 'easeOutExpo',
            duration: 8000,
            autoplay: true,
            loop: true,
            // startDelay: 500,
        })
        tl2.add({
            targets: ['.fakeSlider .ball'],
            left: '90%',
            duration: 5000,

        })
        tl2.add({
            targets: myObject,
            prop1: 20,
            duration: 5000,
            easing: 'easeOutExpo',
            round: 1,
            update: function () {
                numberOfLeadsEl.innerHTML = JSON.stringify(myObject['prop1']);
            }

        }, '-=5000')
        tl2.add({
            targets: ['.fakeSlider .ball'],
            left: '20%',
            duration: 5000,
            easing: 'easeOutExpo',

        })
        tl2.add({
            targets: myObject,
            prop1: 5,
            duration: 5000,

            easing: 'easeOutExpo',
            round: 1,
            update: function () {
                numberOfLeadsEl.innerHTML = JSON.stringify(myObject['prop1']);
            }

        }, '-=5000')
        // tl.add({
        //     targets: ['#map path'],
        //     fill: 'rgba(255,255,255,0)',
        //     duration: 1000,
        //     delay: function (el, i) { return i * 10 },
        // })

    }, [])

    return (
        <FlexWrapper flexDirection="column" height="100%" padding="0 0" >
            <Flexbox className="pricingHeader" justifyContent="center" height="40vh" minHeight="250px" column color="#fff" background="var(--formBackgroundAngled)" width="100%">
                <h1>Pricing and Payment</h1>

            </Flexbox>
            {/* <Flexbox margin="-4rem 0 0" column position="relative" > */}
            <GridContainer margin="-4rem 0 0" padding="0 20px" mobileDisplay="flex" mobileFlexWrap="wrap" gridTemp={{ rows: 'min-content', columns: 'repeat(3,1fr)' }} gridTempMobile={{ rows: 'repeat(2,1fr)', columns: 'min-content min-content' }} justifyContent="center" gap="20px" maxWidth="1000px">
                {pricing.map(it => {
                    return (
                        <Flexbox
                            mobileFlexBasis="30%"
                            mobileFlexGrow="1"
                            gap="20px" column justifyContent="space-between" background="#fff" boxShadow="0 0 5px rgba(0,0,0,0.5)" padding="30px 20px" >
                            <h1 className="gradientText" style={{ margin: '0', textAlign: 'center' }}>{it.heading}</h1>
                            <p style={{ margin: '0', textAlign: 'center' }}>{it.subtext}</p>
                        </Flexbox>
                    )
                })}
            </GridContainer>
            <h3 className="centerText" style={{ margin: '50px 0 0', maxWidth: '90%' }}>
                Exclusive criminal defense leads range from $50-150 each, depending on location.</h3>
            <p style={{ textAlign: 'center', maxWidth: '90%' }}>Some regions may cost more (LA, NYC, SF, Miami, Houston, Dallas, etc.).</p>
            <AnimatedMap />
            <Flexbox width="100%"
                minHeight="80vh"
                padding="50px"
                alignContent="center"
                flexWrap="wrap"
                justifyContent="space-around"
                // background="var(--formBackground)"
                gap="50px">
                <Flexbox column>
                    <img src={priceIcon} height="100px" />
                    <h3>Select leads and make payment</h3>
                    <p style={{ margin: '0', color: 'var(--secondary)', textAlign: 'center', maxWidth: '600px' }}>
                        Fund the number of leads you'd like to try via our payment processor, Stripe.<br /><br />For example, if you want 5 leads from Los Angeles county that cost $100 each, make a $500 payment. We then begin your ad campaign, and you'll receive 5 leads from LA county in real-time as they come in.<br /><br /> Once your budget is depleted, simply make another payment to continue.
                    </p>
                </Flexbox>
                <Flexbox column gap="10px">
                    <h2 style={{ margin: '0', fontSize: '7rem', lineHeight: '0.7em' }} className="numberOfLeads">2</h2>
                    <h2 style={{ margin: '0' }}>Leads</h2>
                    <FakeSlider className="fakeSlider"><div className="ball"></div></FakeSlider>
                </Flexbox>

            </Flexbox>
            {/* <Flexbox justifyContent="center" flexWrap="wrap" padding="0 20px" margin="20px 0 0" row gap="40px" maxWidth="900px" gridTemp={{ rows: 'min-content', columns: 'repeat(2,1fr)' }}>
                {
                    section2.map(section => {
                        return (
                            <FadeIn column gap="20px">
                                <Icon src={section.img} height="80px" width="auto" />
                                <p style={{ margin: '0', textAlign: 'center', maxWidth: '500px' }}>{section.text}</p>
                            </FadeIn>
                        )
                    })
                }
            </Flexbox> */}
            <Flexbox
                column
                height="60vh"
                background="var(--formBackgroundAngled)"
                width="100%"
                alignItems="center"
                justifyContent="center"
                alignContent="center" flexWrap="wrap" padding="50px" margin="0" gap="20px"
            //  maxWidth="900px"
            >
                <h1 className="centerText" style={{ margin: '0', color: '#fff' }}>How To Get Started</h1>
                <h3 style={{ margin: '0', textAlign: 'center', maxWidth: '500px' }}>
                    Fill out our <span style={{ color: '#fff', fontWeight: '700', cursor: 'pointer' }} onClick={() => { navigate('/get-started') }}>contact form</span> to tell us what lead types and locations you want, or simply <MailToLink href="mailto:help@lawyerpayperlead.com">email us</MailToLink>. We will answer all your questions and provide exact pricing.
                </h3>
                <Button boxShadow="0 0 7px rgba(0,0,0,0.5)" text="Get Started" onClick={() => { navigate('/get-started') }} width="90%" maxWidth="300px" />
            </Flexbox>

            {/* <Flexbox column
                background="var(--formBackgroundAngled)"
                width="100%"
                alignItems="center"
                alignContent="center" flexWrap="wrap" padding="50px" margin="80px 0 " gap="20px"
            //  maxWidth="900px"
            >
                <h1 className="centerText" style={{ margin: '0' }}>How To Get Started</h1>
                <h3 style={{ margin: '0', textAlign: 'center', maxWidth: '500px' }}>
                    Fill out our <span style={{ color: 'var(--formBlue1)', fontWeight: '700', cursor: 'pointer' }} onClick={() => { navigate('/get-started') }}>contact form</span> to tell us what lead types and locations you want, or simply email us. We will answer all your questions and provide exact pricing.
                </h3>
                <Button text="Get Started" onClick={() => { navigate('/get-started') }} width="90%" maxWidth="300px" />
            </Flexbox> */}
        </FlexWrapper>
    )
}

export default Pricing