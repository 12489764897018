import styled from 'styled-components'

export const Wrapper = styled.div`
user-select: ${({ userSelect }) => userSelect};
background: ${props => props.background ? props.background : ''};
background-size:cover;
background-blend-mode: ${({ blend = "multiply" }) => blend};
background-position: ${({ bgdPos = "center" }) => bgdPos};
background-image: ${({ backgroundIMG }) => backgroundIMG};
background-size: ${({ bgdSize = "100 100%" }) => bgdSize};
background-repeat: ${({ repeat }) => repeat};
background-attachment: ${({backgroundAttachment}) => backgroundAttachment};
letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : ''};
z-index: ${props => props.zIndex ? props.zIndex : ''};
width: ${({ width }) => width};
min-width: ${({ minWidth }) => minWidth};
max-width: ${props => props.maxWidth ? props.maxWidth : ''};
max-height: ${props => props.maxHeight ? props.maxHeight : ''};
height: ${props => props.height ? props.height : ''};
min-height: ${props => props.minHeight ? props.minHeight : ''};
min-width: ${({ minWidth }) => minWidth};
margin: ${props => props.margin ? props.margin : ''};
box-shadow: ${props => props.boxShadow ? props.boxShadow : ''};
left: ${props => props.left ? props.left : ''};
right: ${props => props.right ? props.right : ''};
top: ${props => props.top ? props.top : ''};
bottom: ${props => props.bottom ? props.bottom : ''};
position: ${props => props.position ? props.position : ''};
flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'}; 
flex-wrap: ${props => props.flexWrap ? props.flexWrap : ''};
flex-grow: ${props => props.flexGrow ? props.flexGrow : ''}; 
flex-shrink: ${props => props.flexShrink ? props.flexShrink : ''}; 
flex-basis: ${props => props.flexBasis ? props.flexBasis : ''};
display: ${props => props.display ? props.display : 'flex'};
gap: ${props => props.gap ? props.gap : ''};
transition: ${props => props.transition ? props.transition : ''};
white-space: ${props => props.whiteSpace ? props.whiteSpace : ''};
text-align: ${({ alignText }) => alignText};
text-transform: ${props => props.transformText ? props.transformText : ''};
justify-content: ${props => props.justifyContent ? props.justifyContent : 'start'};
justify-items: ${props => props.justifyItems ? props.justifyItems : 'start'};
align-content: ${props => props.alignContent ? props.alignContent : 'start'};
align-items: ${props => props.alignItems ? props.alignItems : 'center'};
color: ${props => props.color ? props.color : 'inherit'};
font-size: ${props => props.isHeader ? '10px' : props.fontSize ? props.fontSize : 'var(--fontNormal)'};
font-weight: ${props => props.fontWeight ? props.fontWeight : ''};
font-family: ${({ fontFamily }) => fontFamily};
border-bottom: ${props => props.borderBottom ? props.borderBottom : ''};
border: ${props => props.border ? props.border : ''};
border-right: ${props => props.borderRight ? props.borderRight : ''};
border-radius: ${props => props.borderRadius ? props.borderRadius : ''};
padding: ${props => props.padding ? props.padding : ''};
overflow: ${props => props.overflow ? props.overflow : ''};
overflow-x: ${props => props.overflowX ? props.overflowX : ''};
justify-self: ${props => props.justifySelf ? props.justifySelf : ''};
align-self: ${props => props.alignSelf ? props.alignSelf : ''};
cursor: ${props => props.cursor ? props.cursor : 'default'};
transform: ${props => props.transform ? props.transform : ''};
opacity: ${({ opacity }) => opacity};
grid-row: ${({ gridRow }) => gridRow};
grid-column: ${({ gridCol }) => gridCol};
grid-area: ${({ gridArea }) => gridArea};
scroll-snap-type: ${({ scrollSnapType }) => scrollSnapType};
scroll-snap-align: ${({ scrollSnapAlign }) => scrollSnapAlign};
line-height: ${({ lineHeight }) => lineHeight};
:hover{
            box-shadow: ${props => props.boxShadowHover ? props.boxShadowHover : ''};
        }
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            justify-content: ${({ justifyMobile }) => justifyMobile};
            display: ${({ mobileDisplay }) => mobileDisplay};
            z-index: ${({ mobileZindex }) => mobileZindex};
            visibility: ${({ mobileVisibility }) => mobileVisibility};
            width: ${({ mobileWidth }) => mobileWidth};
            height: ${({ mobileHeight }) => mobileHeight};
            flex-basis: ${({ mobileFlexBasis }) => mobileFlexBasis};
            max-height: ${({ mobileMaxHeight }) => mobileMaxHeight};
            max-width: ${({ mobileMaxWidth }) => mobileMaxWidth};
            min-height: ${({ minHeightMobile }) => minHeightMobile};
            flex-grow: ${({ flexGrowMobile }) => flexGrowMobile}; 
            flex-shrink: ${({ flexShrinkMobile }) => flexShrinkMobile}; 
            flex-wrap: ${({ flexWrapMobile }) => flexWrapMobile};
            margin: ${({ mobileMargin }) => mobileMargin};
            min-width: ${({ mobileMinWidth }) => mobileMinWidth};
            padding: ${({ mobilePadding }) => mobilePadding};
            overflow: ${({overflowMobile}) => overflowMobile};
            flex-direction: ${({flexDirectionMobile}) => flexDirectionMobile};
        }

        

        
`;