import styled from 'styled-components'

export const SVG = styled.svg`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  stroke: ${({ stroke = "#fff" }) => stroke};
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
path {
    transition: all 0.15s ease-in-out;
    stroke-linejoin: round;
    cursor: pointer;
}
path:hover, path.hover {
    fill: ${({ hoverFill = "rgba(255,255,255,.25)" }) => hoverFill};
}
.selected{
    fill: rgba(255,255,255,0.25) !important;
    /* transition: all 0.35s ease-in-out; */
    
}
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
      width: ${({ widthMobile = '100%' }) => widthMobile};
      max-width: ${({ maxWidthMobile = '100%' }) => maxWidthMobile};
    }
`;

export const StateLabel = styled.div`
padding: 3px;
border: 1px solid var(--primary);
border-radius: 5px;
padding: 5px;
cursor: pointer;
    transition: all 0.15s ease-in-out;
:hover, &.selected {
    background: rgba(255,255,255,.25);
  }
`;