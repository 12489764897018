import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import anime from 'animejs'

import PageContentContainer from '../PageContentContainer'

const Loader = styled.div`
background: transparent;
border-radius: 50%;
position: relative;
height: 48px;
width: 48px;

&:before {
    
    content: '';
    border-radius: 50%;
    height: 48px;
    position: absolute;
    width: 48px;
    border: 2px solid #FFF;
    animation: ripple 2s linear infinite;
}
&:after {
    
    content: '';
    border-radius: 50%;
    height: 48px;
    position: absolute;
    width: 48px;
    border: 2px solid #FFF;
    animation: ripple 2s linear infinite;
    animation-delay: 1s;
}
@keyframes ripple{
    0%{
        opacity: 1;
        transform: scale(0);
    }
    100%{
        opacity: 0;
        transform: scale(1);
    }
}
`;

const Submitting = () => {
    useEffect(() => {
        // anime({
        //     targets: '.path',
        //     strokeDashoffset: [anime.setDashoffset, 0],
        //     easing: 'easeInOutSine',
        //     duration: 1500,
        //     delay: function (el, i) { return i * 250 },
        //     // direction: 'alternate',
        //     loop: true
        // })
    }, [])
    return (
        <PageContentContainer
        column
            marginTop="0"
            position="absolute"
            top="0"
            color="#fff"
            height="100vh"
            background="var(--formBackground)"
            justifyContent="center" alignItems="center" flexDirection="row" padding="0 0 50px">
            <h1>Submitting</h1>
            {/* <svg className="circular" viewBox="25 25 50 50" height="200px" width="200px">
                <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" stroke="#fff" />
            </svg> */}
            <Loader />
        </PageContentContainer>
    )
}

export default Submitting